import { Controller } from "stimulus";
import Pickr from "@simonwep/pickr";
import UrlHelper from '../../src/helper/url.js';
// when supported: https://caniuse.com/input-color - should we change it?
//<input type="color" class="form-control form-control-color" id="exampleColorInput" value="#563d7c" title="Choose your color">

function saveColor(url, attr, color) {
  const data = {}
  data[attr] = color;
  return UrlHelper.callScript(url, {
    method: 'patch',
    data
  })
}
export default class extends Controller {
  static targets = ["picker"]
  static values = {
    url: String,
    param: String,
    color: String
  }

  initialize() {
    this.colorValue = this.colorValue || '#000000';
    this.initPicker();
  }
  // in case we want to go plain HTML, but this is not supported in IE
  // initPicker_alternativ() {
  //   this.element.innerHTML = `<input type="color" value="${this.colorValue}">`;
  //   this.picker = this.element.firstChild;
  //   this.picker.addEventListener('change', event=>saveColor(this.urlValue, this.paramValue, event.target.value), false);
  // }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.colorValue,

      components: {
        preview: true,
        opacity: false,
        hue: true,
        disabled: !this.editableValue,

        interaction: {
          hex: true,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true,
        },
      },
    });
    this.picker.on('save', (color, _instance) => {
      const data = {}
      data[this.paramValue] = color.toHEXA().toString();
      UrlHelper.callScript(this.urlValue, {
        method: 'patch',
        data
      })
      this.picker.hide();
    });
  }
}