// only used by old User::Skill (not PerformanceReview)
// this has it's own controller General::MultiSelect2AjaxsController

function send_path(url, e, {adding} = {}) {
  const action = adding ? 'added' : 'removed'
  if (adding && e.params.data.newOption) {
    // the handling of new options is not done here
    return
  }

  $.ajax({
    url: url,
    type: 'PATCH',
    data: {
      event: action,
      var_object_id: e.params.data.id
    }
  });
}

function init() {
  $('.js-multi-select2-ajax').each((i, el)=>{
    const url = $(el).data('url');
    $(el).on('select2:select', (e) => send_path(url, e, { adding: true }))
    $(el).on('select2:unselect', (e) => send_path(url, e, { adding: false }))
  })
}

$(document).on("turbolinks:load", function() {
  init();
});
