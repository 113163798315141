// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="showcase">
//   <h1 data-target="showcase.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "output", "myCode" ]
  static values = {
    init: String
  }

  connect() {
    console.log('Showcase controller got connected in dom.')
    this.myCodeTarget.innerText = this.element.outerHTML
  }

  click() {
    console.log('click')
    this.outputTarget.innerHTML = this.initValue
  }

  inputChanged() {
    console.log(`inputChanged: ${this.inputTarget.value}`)
    this.outputTarget.innerHTML = this.inputTarget.value
  }

  // get attribute to access another controller accessible from here
  get someOtherController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'other-controller-name')
  }

}
