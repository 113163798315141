import { Controller } from "stimulus"

export default class extends Controller {

  static values = { 
    url: String ,
    validationMessage: String
  }

  validates(e) {
    if(this.validation() == true) {
      document.location.href = this.urlValue
    } else {
      alert(this.validationMessageValue)
    }
    e.preventDefault()
  }

  validation() {
    return document.getElementsByClassName('best_in_place')[0].hasAttribute('data-bip-value')
      && document.getElementsByClassName('best_in_place')[1].hasAttribute('data-bip-value')
      && document.getElementsByClassName('best_in_place')[2].hasAttribute('data-bip-value')
  }
}
