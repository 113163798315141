import { Controller } from "stimulus"
import * as pdfjsLib from 'pdfjs-dist/webpack';

export default class extends Controller {
  static values = {
    url: String,
    currentPage: Number
  }

  static targets = [ "pageNumber", "pageCount" ]
  static pdfDocument = null

  connect() {
    this.currentPageValue = 1;
    this.loadPDF();
  }

  loadPDF(){
    if (this.urlValue.length == 0) { return };
    const loadingTask = pdfjsLib.getDocument(this.urlValue);

    loadingTask.promise.then( pdfDocument_ => {
      this.pdfDocument = pdfDocument_;
      this.pageCountTarget.textContent = this.pdfDocument.numPages
      this.renderPage(this.currentPageValue);
      this.updatePageNumber(this.currentPageValue);
    });
  }

  renderPage(num) {
    // Using promise to fetch the page
    this.pdfDocument.getPage(num).then(function(pdfPage) {
      const viewport = pdfPage.getViewport({ scale: 1.0 });
      const canvas = document.getElementById("pdf-canvas");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport
      };
      var renderTask = pdfPage.render(renderContext);

      return renderTask.promise;
    });
  }

  reloadPDF() {
    this.loadPDF();
  }

  previousPagePDF() {
    if (this.currentPageValue === 1) { return };
    this.updatePageNumber(this.currentPageValue -= 1);
    this.renderPage(this.currentPageValue);
  }

  nextPagePDF() {
    if (this.currentPageValue === parseInt(document.getElementById('pageCount').textContent)) { return };
    this.updatePageNumber(this.currentPageValue += 1);
    this.renderPage(this.currentPageValue);    
  }

  updatePageNumber(number) {
    this.pageNumberTarget.textContent = number;
  }
}
