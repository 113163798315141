/**
<span data-controller="dropzone" class="dropzone-projo"
      data-dropzone-param-name-value="customizing_official_paper[<%= part[:type] %>]"
      data-dropzone-method-value="put"
      data-dropzone-accepted-files-value="application/pdf"
      data-dropzone-url-value="<%= customizing_official_paper_path(official_paper, format: :html)%>"
      data-dropzone-reload-on-success-value="true"
      >
  <div class='glyphicon glyphicon-file dz-message text-muted'></div>
</span>

 * use class 'dz-message' in dropzone are, if object should let the click through (i.e. opens upload form)
 */
import { Controller } from "stimulus"
import Dropzone from "dropzone";
// we can't really mixed them...
//Dropzone.autoDiscover = false;

export default class extends Controller {

  static csfr_token
  static values = {
    paramName: String,
    url: String,
    method: String,
    acceptedFiles: String,
    reloadOnSuccess: Boolean
  }

  connect() {

    this.paramNameValue ||= 'file'
    this.methodValue ||= 'post'
    this.acceptedFilesValue ||= ''
    this.csfr_token = $('meta[name="csrf-token"]').attr('content')
    const thisController = this;

    // cleaner for turbo, when trying to reload
    if (this.element.dropzone) {
      this.element.dropzone.destroy()
    }

    $(this.element).dropzone({
      maxFiles: 1,
      createImageThumbnails: false,
      dictDefaultMessage: '',
      clickable: true,
      paramName: this.paramNameValue,
      url: this.urlValue,
      method: this.methodValue,
      acceptedFiles: this.acceptedFilesValue,
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      init: function() {
        this.on("sending", () => $(thisController.element).spin())
        this.on("success", (response) => {
          $(thisController.element).spin(false);

          try {
            if(JSON.parse(response.xhr.response).redirect_url !== undefined) {
              location.href = JSON.parse(response.xhr.response).redirect_url;
            } else if(thisController.reloadOnSuccessValue) {
              location.reload();
            }
          } catch(e){
            if(thisController.reloadOnSuccessValue) {
              location.reload();
            }
          }

        });
        this.on("error", (fileObject, response) => {
          this.removeAllFiles();
          let msg
          try {
            msg = JSON.parse(response).join(', ')
          } catch (e) {
            msg = response;
          }
          $(thisController.element).spin(false);

          App.flashCmdHelper.handle({ show: {message: msg, type: 'danger'}})

        });
      }
    })

    $(this.element).addClass("dropzone-projo")
  }
}
