/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/js/i18n/de';

function createSelect2(parentNode, creationOptions = {}) {
  if (parentNode.hasClass('_initialized_') || $(parentNode).length == 0) { return; }
  parentNode.addClass('_initialized_');

  // add data-select2-
  const options = Object.assign({}, extractOptions(parentNode), creationOptions)

  if (parentNode.data('placeholder')) options.placeholder = parentNode.data('placeholder');
  const parent = $(parentNode).parent();
  if (parent) { options.dropdownParent = parent }

  return parentNode.select2({
    ...options,
    theme: "bootstrap-5",
  });
}

function extractOptions($element) {
  const prefix = 'select2';
  const dataset = $element.data();
  return Object.keys(dataset)
  .filter(key => key.startsWith(prefix) && key.length > prefix.length)
  .reduce((obj, originalKey) => {
    let newKey = originalKey.substring(prefix.length)
    newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1)
    obj[newKey] = dataset[originalKey];
    return obj;
  }, {});
}

App.Select2 = class Select2 {
  constructor($elements) {
    $elements.each((_i, e) => {
      const $element = $(e);

      const select2 = createSelect2($element);
      if (!select2) { return; }

      if ($element.hasClass('js-select2-keep-closed')) {
        select2.select2({
          allowClear: true
        }).on('select2:unselecting', function(e) {
          // needed in filter pane, as list opened when removing an element
          return $(this).data('unselecting', true);
        }).on('select2:opening', function(e) {
          if ($(this).data('unselecting')) {
            $(this).removeData('unselecting');
            return e.preventDefault();
          }
        });
      }
    })
    // this removes the title from the options, there seem to be no better way...
    // otherwise the tooltip remains active when the option is removed.
    $('span.select2-container').find('[data-bs-original-title]').removeAttr('data-bs-original-title')

  }
  static init() {
    return new App.Select2($('select.form-control, .select2').not('.js-colorpicker, .js-create-tag, ._initialized_, .select2-container'));
  }
};

App.Select2CreateTag = class Select2CreateTag {
  constructor($elements) {
    $elements.each((_i, e) => {
      const element = $(e);
      const select2 = createSelect2(element, {
        tags: true,
        createTag(params) {
          return {
            id: params.term,
            text: params.term,
            newOption: true
          };
        },
        templateResult(data) {
          const $result = $('<span></span>');
          $result.text(data.text);
          if (data.newOption) {
            $result.append(' <b><font color="red">(neu)</font></b>');
          }
          return $result;
        },
        insertTag(data, tag) {
          return data.push(tag);
        }
      });
      if (!select2) { return; }

      select2.on('select2:select', function(e) {
        if (e.params.data.newOption) {
          const option = element.find('[data-select2-tag="true"]');
          const name = e.params.data.text;
          const url_with_params = element.data('href');
          const url = url_with_params.split('?')[0];
          let params = url_with_params.split('?')[1];
          let attribute = element.data('attribute');
          const data = { [attribute]: name };
          if (params) {
            params = params.replace(/%5B/g, '[').replace(/%5D/g, ']');
            const attributes = params.split('&');
            for (attribute of Array.from(attributes)) {
              const key = attribute.split('=')[0];
              const value = attribute.split('=')[1];
              $.extend(data, {[key]: value});
            }
          }

          return $.ajax({
            data,
            dataType: "JSON",
            type: "POST",
            url
          }).done(function(obj) {
            const id = obj['id'];
            if (id) {
              option.replaceWith(`<option selected value='${id}'>${name}</option>`);
              if ($('.js-customer-details-of-new-customer').length > 0) {
                $('.js-customer-details-of-new-customer').removeClass('hide');
                $('.js-customer-details-of-new-customer').find(':input').each( function() {
                  $(this).prop( "disabled", false );
                  return $(this).val('');
                });
                return $('#js-customer-id').val(id);
              }
            } else {
              return option.replaceWith("<option selected>Fehler</option>");
            }

          });
        }
      })
    })
  }
  static init() {
    return new App.Select2CreateTag($('.js-create-tag').not('._initialized_, .select2-container'));
  }
};


$(document).on("turbolinks:load", function() {
  App.Select2.init();
  App.Select2CreateTag.init();

  // https://stackoverflow.com/questions/25882999/set-focus-to-search-text-field-when-we-click-on-select-2-drop-down#comment121520124_67691578
  $(document).on('select2:open', (e) => {
      const selectId = e.target.id
  
      $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
          key,
          value,
      ){
          value.focus();
      })
  })
});



  // integrate select2 into best_in_place
  // https://gist.github.com/argent-smith/3835854
  // first, find the mount point
  // $('body').on 'best_in_place:activate', '.best_in_place', ->
  //   # second, attach empty update() to `this` to screen the downstream calls
  //   # which otherwise will cause an error
  //   @update = ->
  //   # now unbind `blur` from the <select> and bind it to the downstream
  //   # best_in_place's handler; this prevents the immediate select2's
  //   # destruction since clicking on it would cause `blur` on the downstream
  //   # best_in_place
  //   $(@).find('select').select2()
  //     .unbind('blur').bind('blur', {editor: @}, BestInPlaceEditor.forms.select.blurHandler)
//