import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { queryUrl: String, currentlyDeletingUrl: String, deleteUrl: String }
  static targets = ['result']

  connect() {
    console.log("Connected..")
    // We should eventually load this list from html so avoid having to update
    // this when we add more models
    this.models ||= [
      "Absence::Absence",
      "User::User",
      "TimeTracking::Booking",
      "Work::Treaty",
      "BusinessPartner::Customer",
      "Invoicing::Invoice",
      "Invoicing::IncomingInvoice",
      "Document::Document"
    ]
    
    this.statusMap ||= this.models.map(model => {
      return { model: model, status: "ready" }
    })

    this.current_view = this.models[0]
    this.getDeletionStatus()
  }

  handleChange(event) {
    let model = event.srcElement.value
    this.current_view = model
    this.render()
  }

  handleDelete(event) {
    console.log("Deleting...")
    event.preventDefault()
    let model = this.current_view
    this.deleteModel(model)
    this.statusMap.find(status => status.model == model).status = "deleting"
    window.statusMap = this.statusMap
    this.render()
  }

  getModel(model) {
    fetch(this.queryUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({ model: model })
    })
      .then(response => response.text())
      .then(data => this.resultTarget.innerHTML = data)
  }

  deleteModel(model) {
    fetch(this.deleteUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({ model: model })
    })
  }

  getDeletionStatus() {
    fetch(this.currentlyDeletingUrlValue, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        // response has the format { "queued_jobs": ["User::User", ...], "running_jobs": ["Absence::Absence"] }
        window.data = data
        window.statusMap = this.statusMap
        this.models.forEach(model => {
          let status = this.statusMap.find(status => status.model == model)
          if (data.queued_jobs.includes(model)) {
            status.status = "queued"
          } else if (data.running_jobs.includes(model)) {
            status.status = "deleting"
          } else {
            status.status = "ready"
          }
        })
        this.render()
      })

  }

  checkDeletion() {
    this.getDeletionStatus()
  }

  render() {
    if (this.statusMap.find(status => status.model == this.current_view).status == "deleting") {
      this.resultTarget.innerHTML = "Deleting..."
      setTimeout(() => this.checkDeletion(), 10000)
    } else if (this.statusMap.find(status => status.model == this.current_view).status == "queued") {
      this.resultTarget.innerHTML = "Queued..."
      setTimeout(() => this.checkDeletion(), 10000)
    } else {
      this.getModel(this.current_view)
    }
  }
}
