let popoverList = []

function init() {
  let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  popoverList = popoverList.concat(popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, {
      placement: 'top',
      trigger: 'hover',
      html: true
    })
  }))
  $('[data-bs-toggle="ajax-popover"]').on('click', function() {
    const that = $(this);
    that.off('click');
    return $.get(that.data('href'), data => //console.log data
    {
      let popover = new bootstrap.Popover(that, {
      'html': true,
      'content': data.content,
      'title': that.data('absence-type')
      })
      popoverList.push(popover)
      return popover
    });
  });
}
// $('[data-bs-toggle="ajax-popover"]').on('click', function() {
//   const that = $(this);
//   that.off('click');
//   return $.get(that.data('href'), data => //console.log data
//   that.popover({
//     'html': true,
//     'content': data.content,
//     'title': that.data('absence-type')
//   }).popover('show'));
// });
function hide() {
  popoverList.forEach( popover => popover.hide())
}
App.Popover = {
  init,
  hide
}

export default App.Popover