/*
<button class="btn btn-danger float-right"
  data-controller="modal-drop-changes"
  data-modal-drop-changes-message-value="<%= t('defaults.destroy_confirm.body') %>"
  data-action="modal-drop-changes#check">
  <span class='glyphicon glyphicon-trash'></span>
  <%= t('defaults.drop_changes') %>
</button>
*/

import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    message: String
  }

  connect() {
  }

  check(event) {
    if (confirm(this.messageValue)) {
      $(event.target).parents('form')[0].dataset.do_not_submit = true;
      App.Modal.close();
    }

    event.preventDefault();
  }
}
