/*
 * BestInPlace 3.0.0.alpha (2014)
 *
 * Depends:
 *  best_in_place.js
 *  bootstrap-datepicker.js
 */
/*global BestInPlaceEditor */
BestInPlaceEditor.forms.date = {
    activateForm: function () {
        'use strict';
        //debugger
        var that = this,
            output = jQuery(document.createElement('form'))
                .addClass('form_in_place')
                .attr('action', 'javascript:void(0);')
                .attr('style', 'display:inline'),
            input_elt = jQuery(document.createElement('input'))
                .attr('type', 'text')
                .attr('name', this.attributeName)
                //.attr('value', this.sanitizeValue(this.display_value));
                 .attr('value', this.sanitizeValue(this.oldValue));

        if (this.inner_class !== null) {
            input_elt.addClass(this.inner_class);
        }
        output.append(input_elt);

        this.element.html(output);
        this.setHtmlAttributes();
        this.element.find('input')[0].select();
        this.element.find("form").bind('submit', {editor: this}, BestInPlaceEditor.forms.input.submitHandler);
        this.element.find("input").bind('keyup', {editor: this}, BestInPlaceEditor.forms.input.keyupHandler);

        var input = this.element.find("input");
        input.prop("type", "date_picker");
        input.datepicker({
                format: 'dd.mm.yyyy',
                language: 'de', //TODO this.element.data('language')
                todayHighlight: true,
                todayBtn: true,
                autoclose: true
            }).on('hide', function(){
                that.update();
            }).on('changeDate', function(){
                // Ben: before commenting out, the datepicker showed a weird behaviour.
                // When typing in the date, it closes the popup
                // $(this).datepicker('hide');
            })
            .datepicker('show');
    },

    getValue: function () {
        'use strict';
        return this.sanitizeValue(this.element.find("input").val());
    },

    submitHandler: function (event) {
        'use strict';
        event.data.editor.update();
    },

    keyupHandler: function (event) {
        'use strict';
        if (event.keyCode === 27) {
            event.data.editor.abort();
        }
    }
};

(function() {
  //store current bip click handler
  var chFn = BestInPlaceEditor.prototype.clickHandler
  // wrap it
  BestInPlaceEditor.prototype.clickHandler = function () {
    var $this = $(this);
    if ($this.data('confirm')) {
      // confirmation configured

      if ($this.data('confirm-bip-only-if-content') !== undefined) {
        if ($this.data('bip-original-content') !== $this.data('confirm-bip-only-if-content')) {
          // confirmation not needed according to current content
          return chFn.apply(this, arguments);
        }
      }


      if ($.rails.allowAction($this)) {
        //confirmation needed and provided
        chFn.apply(this, arguments);
      }
    } else {
      // no confirmation needed
      chFn.apply(this, arguments);
    }
  }
})();
