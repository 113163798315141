const REFRESH_RATE = 5000
const poller_nodes = [];

function check_status_via_ajax($e) {
  const url = $e.data('href')

  $.ajax(url, {
    dataType: "json",
    success:(data, textStatus, jqXHR) => {
      const message = data.message
      if (message == "error") {
        stop_polling($e)
        $e.replaceWith("<div class='btn btn-danger disabled'>Es ist ein Fehler aufgetreten.</div>")
      } else if (message == 'success') {
        stop_polling($e)
        $e.html('fertig!')
        if (data.path_after_done) {
          Turbolinks.visit(data.path_after_done)
        }
      } else if (message == 'running') {
        console.log('polling running...')
      }
    },
    error: (XMLHttpRequest, textStatus, errorThrown) => {
      stop_polling($e)
      console.log(XMLHttpRequest)
      setTimeout(()=>location.reload() , 15000)
    }
  })
}

function poll($e) {
  if ($e.data('polling')) {
    check_status_via_ajax($e)
    $e.data('timeout_polling', setTimeout(()=>poll($e), REFRESH_RATE))
  }
}

function start_polling($e) {
  if ($e.data('polling')) { return }

  poller_nodes.push($e)

  $e.data('polling', true);
  poll($e)
}

function stop_polling($e) {
  $e.data('polling', false);
  clearTimeout($e.data('timeout_polling'));
}

function init(selector = '.js-bg-job-running') {
  const elems = $(selector)
  if (!(elems.length > 0)) { return }

  elems.each( (_i, e)=>{
    const $e = $(e)
    start_polling($e)
  })
}

function clean() {
  poller_nodes.forEach($e=>stop_polling($e))
}

App.BgJob = {
  init,
  clean
}

$(document).on("turbolinks:before-cache", function() {
  // when leaving clear all timers.
  App.BgJob.clean();
});
