(function() {
  // show notifications once per day
  const sameDay = (someDate, today = new Date()) =>
      someDate.getFullYear() === today.getFullYear() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getDate() === today.getDate();
  const sameWeek = (someDate, today = new Date()) =>
      someDate.getFullYear() === today.getFullYear() &&
      someDate.getMonth() === today.getMonth() &&
      moment(someDate).week() === moment(today).week();

  App.Session = {
    store(key, value, { raw = false } = {}) {
      if (typeof(Storage) !== "undefined") {
        window.localStorage.setItem(key, raw ? value : JSON.stringify(value))
      } // else we could implement something for really old browser, if there are any left.
    },
    retrieve(key, { default_value = null, raw = false } = {}) {
      if (typeof(Storage) !== "undefined") {
        const str_value = window.localStorage.getItem(key)
        if (!str_value) {
          return default_value;
        } else {
          return raw ? str_value : JSON.parse(str_value);
        }
      }
    },

    doOncePerDay(key, fn) {
      const lastCall = new Date(App.Session.retrieve(key, { default_value: 0 }));
      if (!sameDay(lastCall)) {
        fn();
      }
    },

    doOncePerWeek(key, fn) {
      const lastCall = new Date(App.Session.retrieve(key, { default_value: 0 }));
      if (!sameWeek(lastCall)) {
        fn();
      }
    }
  }

  $(document).on("turbolinks:load", () => {
    App.Session.doOncePerDay('popup_notifications_last_seen', () => { $('#notification_count').find('a').click(); } )

  });
})(App);