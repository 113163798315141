import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AnnotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables, ChartDataLabels, AnnotationPlugin);
Chart.defaults.set('plugins.datalabels', {
  display: false
});


function mergeDeep(...objects) {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    if (obj) {
      Object.keys(obj).forEach(key => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        }
        else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = mergeDeep(pVal, oVal);
        }
        else {
          prev[key] = oVal;
        }
      });
    }

    return prev;
  }, {});
}

window.Chart = Chart; //BAD

App.chartFn = (function(){

  function moving_average(data) {
    var points = 2;
    var mov = Array(points).fill(data[0]).concat(data.slice(0, points + 1));
    let result = [];

    for (var i = 0; i < data.length; i++) {
      mov.unshift(data[Math.min(points + i, data.length - 1) ]);
      mov.pop();
      result.push(avg(mov))
    }
    return result;
  }

  function avg(data) {
    var result = 0.0;
    for (var i = 0; i < data.length; i++) {
      result += parseInt(data[i]);
    }
    return result / data.length;
  }

  function trend(data) {
    let sumXY = 0;
    let sumY = 0;
    let sumX = 0;
    let sumXSq = 0;
    let counter = 0;

    data.forEach(function(d) {
      sumY += d;
      sumX += counter;
      sumXSq += counter * counter;
      sumXY += d * counter;
      counter ++;
    });

    var m = ((sumXY - sumX * sumY / data.length) ) / (sumXSq - sumX * sumX / data.length);
    var b = sumY / data.length - m * sumX / data.length;

    var trendFn = function(x) {
      return m * x + b;
    }
    counter = 0;
    return data.map(function() {
      return trendFn(counter++);
    });
  }

  return {
    moving_average: moving_average,
    avg: avg,
    trend: trend
  }
})();


// const horizontalLinePlugin = {
//   id: 'horizontalLinePlugin',
//   renderHorizontalLine(chartInstance, value) {
//     console.log('1.renderHorizontalLine')
//     const horizontal_scale = chartInstance.scales.x;
//     const vertical_scale = chartInstance.scales.y;
//     const context = chartInstance.chart.ctx;
//     // render horizontal line
//     context.beginPath();
//     context.strokeStyle = 'black';
//     //#ff0000'
//     const linePositionY = vertical_scale.getPixelForValue(value);
//     context.moveTo(horizontal_scale.left, linePositionY);
//     context.lineTo(horizontal_scale.right, linePositionY);
//     context.stroke();
//   },
//   afterDatasetsDraw(chart, easing) {
//     if (chart.config.horizontalLineAt) {
//       this.renderHorizontalLine(chart, chart.config.horizontalLineAt);
//     }
//   }
// };

// const verticalLinePlugin = {
//   id: 'verticalLinePlugin',
//   getLinePosition(chart, pointIndex, dataset) {
//       const meta = chart.getDatasetMeta(dataset); // first dataset is used to discover X coordinate of a point
//       const {
//         data
//       } = meta;
//       const firstPointIndex = Math.floor(pointIndex);
//       if (firstPointIndex == pointIndex) {
//         return data[pointIndex].x;
//       } else {
//         return (data[firstPointIndex].x + data[firstPointIndex + 1].x) / 2;
//       }
//     },
//   renderVerticalLine(chartInstance, pointIndex, label, options) {
//     if (!options) { options = {}; }
//     if (!options.color) { options.color = '#ff0000'; }
//     if (!options.dataset_for_pos) { options.dataset_for_pos = 0; }
//     if (!options.text_align) { options.text_align = 'center'; }
//     if (!options.vertical_align) { options.vertical_align = 'middle'; }

//     const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex, options.dataset_for_pos);
//     let scale = chartInstance.scales.y;
//     if (!scale) {
//       // heruristic, try to find some y scale. We may add a configuration for this if needed.
//       some_scale_name = chartInstance.scales.x[0];
//       scale = chartInstance.scales[some_scale_name];
//     }
//     const context = chartInstance.ctx;

//     // render vertical line
//     context.beginPath();
//     context.strokeStyle = options.color;
//     context.moveTo(lineLeftOffset, scale.top);
//     context.lineTo(lineLeftOffset, scale.bottom);
//     context.stroke();

//     // write label
//     context.fillStyle = options.color;
//     context.textAlign = options.text_align;
//     let vertical_pos = ((scale.bottom - scale.top) / 2) + scale.top;
//     if (options.vertical_align === 'bottom') {
//       vertical_pos = scale.bottom - ((scale.bottom - scale.top) / 10);
//     }
//     return context.fillText(label, lineLeftOffset, vertical_pos);
//    },
//   afterDatasetsDraw(chart, easing) {
//     if (chart.config.verticalLines) {
//       return Array.from(chart.config.verticalLines).map((vl) =>
//         this.renderVerticalLine(chart, vl.pos, vl.label, vl.options));
//     }
//   }
// };

function configHorizontalLine({id = 'max', color = '#000000', pos}={}) {
  // console.log(`line ${id} at ${pos}`)
  return {
    plugins: {
      annotation: {
        annotations:{
          [id]: {
            type: 'line',
            scaleID: 'y',
            value: pos,
            borderWidth: 1,
            borderColor: color
          }
        }
      }
    }
  }
}

function configVerticalLine({id = 'line', color = 'red', label, pos} = {}) {
  return {
    plugins: {
      annotation: {
        annotations:{
          [id]: {
            type: 'line',
            scaleID: 'x',
            borderWidth: 1,
            borderColor: color,
            value: pos,
            label: {
              rotation: 'auto',
              position: 'start',
              backgroundColor: 'rgba(255,255,255,0.9)',
              color: color,
              xPadding: 2,
              yPadding: 2,
              enabled: !!label,
              content: label
            }
          }
        }
      }
    }
  }
}

App.ChartJsPlugins = {
  markYears({ color = '#cccccc' } = {}) {
    // returns an object that can compute the year changes in the labels
    return {
      compute: (datesLabels) => {
        const verticalLines = [];
        if (!datesLabels) {
          return verticalLines;
        }
        const years = datesLabels.map(l => {
          let year = l.match(/\d{4}/);
          return year ? year[0] : null;
        });
        if (years.filter( year => year !== null).length == datesLabels.length) {
          let last_year = years[0];
          let pos = 0;
          years.forEach( year => {
            if (year !== last_year) {
              verticalLines.push({
                pos: pos - 0.5,
                label: `${year}`,
                color: color
              })
              last_year = year;
            }
            pos++;
          })
        } else {
          if (easing == 1) {
            console.log('year not detected');
          };
        }
        return verticalLines;
      }
    }
  }
}

App.ChartJs = class ChartJs {

  constructor(id, options = {}) {
    this.id = id;
    this.data_copy = JSON.parse(JSON.stringify(options.data || this._emptyDataset()));
    this.options = options;

    const canvas = this._init_html(id);
    this.ctx = canvas.getContext('2d');
    this.chart = new Chart(this.ctx, this.initOptions(options));
  }

  initOptions(options = {}) {
    const optionsToMerge = [this._basicOptions(), options.options];

    if (options.useMoneyAxis) {
      optionsToMerge.push(this.moneyAxes(options.options));
    }

    if (options.horizontalLineAt !== undefined) {
      if (typeof options.horizontalLineAt === 'number') {
        options.horizontalLineAt = {
          pos: options.horizontalLineAt
        }
      }
      optionsToMerge.push(configHorizontalLine(options.horizontalLineAt));
    }


    if (options.verticalLines) {
      // we need to store the creation function as in `project_controlling` we recreate the graph with new data
      let computeFn = null;
      if (options.verticalLines.compute) {
        options._compute = options._compute || {}
        options._compute.verticalLines = computeFn = options.verticalLines.compute
      } else if (options._compute && options._compute.verticalLines) {
        computeFn = options._compute.verticalLines
      }
      if (computeFn) {
        options.verticalLines = computeFn(options.data.labels)
      }
      options.verticalLines.forEach((vl,i) => optionsToMerge.push(configVerticalLine({id: 'line_' + i, ...vl})))
    }

    options.options = mergeDeep({}, ...optionsToMerge);
    if (!options.options.onHover) { options.options.onHover = this.mark_column; }
    if (!options.options.onClick) { options.options.onClick = this.mark_column; }
    return options;
  }

  _basicOptions() {
    return {
      tension: 0.4,
      pointRadius: 2,
      pointHitRadius: 4,
      pointBackgroundColor: '#ffffff',
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              const dataset = context.dataset;
              //const value = context.parsed.y || context.parsed;
              const value = context.raw;
              const entry = context.element;
              const label = context.label;

              let formattedValue;
              switch (dataset.valueFormat) {
                case 'money': {
                  formattedValue = App.i18n.money(value);
                  break;
                }
                case 'money_cent': {
                  formattedValue = App.i18n.money(value / 100);
                  break;
                }
                case 'hour': {
                  formattedValue = `${value} h`;
                  break;
                }
                case 'percentage': {
                  formattedValue = `${value} %`;
                  break;
                }
                case 'labeled': {
                  formattedValue = `${label}: ${value}`;
                  break;
                }
                default : {
                  formattedValue = context.formattedValue;
                }
              }
              return formattedValue;
            }
          }
        }
      }
    }
  }
  moneyAxes() {
    return {
      scales: {
        y: {
          ticks: {
            callback: function(value, _index, _values) {
              return App.i18n.money(value);
            }
          }
        }
      }
    }
  }
  update(datasets) {
    let data;
    if (!datasets || Object.keys(datasets).length == 0) {
      data = this._emptyDataset();
    } else {
      data = datasets
    }
    // recreate the graph, with the new data, as some plugins need to be reconfigured
    this.options.data = data;
    this.data_copy = JSON.parse(JSON.stringify(data));
    this.chart.destroy();
    this.chart = new Chart(this.ctx, this.initOptions(this.options));
  }

  mark_column(_, elems) {
    const marks = $("table .chart-column");
    const e = elems[0];
    if (e && marks.length) {
      const idx = e.index + 2;
      marks.removeClass("bg-dark text-light");
      const column_marks = $("table .chart-column:nth-child(" + idx + ")");
      if (column_marks.length) {
        $("table .chart-column:nth-child(" + idx + ")").addClass("bg-dark text-light");
        $("table .chart-column:nth-child(" + idx + ")")[0].scrollIntoView({ block: 'nearest'});
      }
    }
  }
  _emptyDataset() { return { datasets: [], labels: [] }; }

  _init_html(id) {
    const that = this;
    let target = $(`#${id}`);
    if(target.length == 0) {
      console.error('No target');
      return target;
    }

    target.find('#soften').on('click', function() {
      $('#reset').prop('disabled', false);
      that.chart.data.datasets.forEach(function(ds) {
        ds.data = App.chartFn.moving_average(ds.data);
      });
      that.chart.update();
    });

    target.find('#show_trend').on('click', function() {
      $('#reset').prop('disabled', false);
      that.chart.data.datasets.forEach(function(ds) {
        ds.data = App.chartFn.trend(ds.data);
      });
      that.chart.update();
    });

    target.find('#reset').on('click', function() {
      $('#reset').prop('disabled', true);
      that.chart.data = JSON.parse(JSON.stringify(that.data_copy));
      that.chart.update();
    });

    return target.find('canvas')[0];
  }
};
