// handle booking mover selection logic

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bookings" ]
  static values = {
    total: Number
  }

  static allVisibleSelected = false // if all visible are selected
  static allSelected = false        // if all are selected (more than the visible ones)
  static maxSelection = 0           // the max umber of selectable items ("visible")

  connect() {
    this.maxSelection = $(this.element).find('.booking_check_box').length
    this.selection_change();
  }

  selection_change() {
    const $elem = $(this.element)
    const count = $elem.find('.booking_check_box').filter('input:checked').length
    this.allVisibleSelected = count == this.maxSelection
    this.allSelected = false  // if anything changes, we assume "not all" are selected (all includes non visible)

    $elem.find('.js-show-when-selection')
      .addClass('js-enrich-url-by-booking-ids')
      .removeClass('d-none')

    if (this.hasBookingsTarget) {
      this.bookingsTargets.forEach(e => e.innerHTML = count)
    }

    if (count >= this.maxSelection) {
      this.allVisibleSelected = true
    } else {
      this.allVisibleSelected = false

      if (count == 0) {
        $('.js-show-when-selection').addClass('d-none')
      }
    }
    this._update_classes();

  }

  // selects all (more than what can be seen)
  select_all() {
    const select = !this.allSelected
    this.select_all_visible(null, select)
    this.allSelected = select

    this._update_classes();

    if (select) {
      // need to overwrite values
      if (this.hasBookingsTarget) {
        this.bookingsTargets.forEach(e => e.innerHTML = this.totalValue)
      }

      // TODO: improve this logic... to signal all bookings, no id should be sent
      // ids will be added in the modal (?!) if this class is present.
      $(this.element).find('.js-show-when-selection').removeClass('js-enrich-url-by-booking-ids')
    }
  }

  // selects all visible.
  // Default logic: toggle. Can be overridden by second parameter.
  select_all_visible(_event, select = null) {
    if (select === null) {
      select = !this.allVisibleSelected
    }
    $(this.element).find('.booking_check_box').prop('checked', select);
    this.selection_change()
  }

  _update_classes() {
    $(this.element).find('.booking-mover--all-selected').toggle(this.allSelected);
    $(this.element).find('.booking-mover--not-all-selected').toggle(!this.allSelected);
    $(this.element).find('.booking-mover--all-visible-selected').toggle(this.allVisibleSelected);
    $(this.element).find('.booking-mover--not-all-visible-selected').toggle(!this.allVisibleSelected);
  }
}
