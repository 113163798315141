import { Controller } from "stimulus";
import $ from 'jquery';
import 'timepicker';

// Adds a time-picking drop-down to appropriate input fields.
// Currently only supports duration, but must soon be expanded.
export default class extends Controller {
  connect() {
    var forceRound = this.data.get('forceRoundTime') == 'true';
    var options = {
      // Hours without leading zero, colon, minute, and literal h. "4:00 h" for example.
      timeFormat: 'G:i \\h',
      // Increment between select options
      step: 15,
      // Show drop-down in bottom-left
      orientation: 'bl',
      // This could be enabled to disallow free-form
      disableTextInput: false,
      minTime: '00:15',
      maxTime: '23:45',
      // This should be true if the office selects to only accept time bookings in 15min increments
      forceRoundTime: forceRound,
      // Turns the nice typable box in into a slightly too simple select box.
      useSelect: false,
      // Chose an option when they tab on to next thing or click elsewhere.
      selectOnBlur: true,
      selectOnTab: true,
      // We only want to show the dropdown on click
      showOn: ['click']
    }

    // If we use decimal notation (2,5h instead of 2:30h) the timepicker doesn't make sense.
    if (this.element.dataset.duration == 'formatted') {
      $(this.element).timepicker(options);
    }

    // Always preselect the time so immediately writing will overwrite it
    $(this.element).select();
  }
}
