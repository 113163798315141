import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "hideable" ]
  
  answerYes() {
    this.hideableTargets.forEach(el => {
      el.hidden = true
    });
  }

  answerNo() {
    this.hideableTargets.forEach((el) => {
      el.hidden = !el.hidden
    });
    document.getElementById("spreadsheet").classList.remove("mt-4");
  }
}
