App.HonorarZoneTable = function(element) {
  this.ns = 'hzt';

  $(element).unbind('click.' + this.ns).bind('click.' + this.ns, function (event) {
    console.log('!!!')
    var cell = $(event.target).data();
    if (cell) { 
      var attr_prefix = cell.hoas2 ? 'hoas2_merkmal' : 'merkmal';
      var attr = attr_prefix + cell.row;
      var data = {hoai_honorar_zone_table: {}}
      data.hoai_honorar_zone_table[attr] = cell.value

      $.ajax({
        url: location.pathname,
        method: 'put',
        data: data
      }).done(function () {
        //use refresh action like bip
        $.ajax({dataType: 'script', url: location.pathname + '/refresh'});
      });
    }
  });
}

$(document).on("turbolinks:load", function() {new App.HonorarZoneTable($('.hzt-cell'))});
