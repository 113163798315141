/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;

    if ("value" in descriptor)
      descriptor.writable = true;

    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps)
    _defineProperties(Constructor.prototype, protoProps);

  if (staticProps)
    _defineProperties(Constructor, staticProps);

  return Constructor;
}

App.FilterPane = function() {
  "use strict";
  function FilterPane(el) {
    _classCallCheck(this, FilterPane);
    this.el = el;
    var that = this;
    this.el.find('.js-filter-element').each( function() {
      var target = $(this);
      var filter_pane = target.closest('.js-filter-pane');
      if (target.hasClass('select2')) {
        target.on('change', function(e) {
          return that.value_changed(filter_pane);
        });
      } else if (target.hasClass('js-toggle-nav-button')) {
        target.on('click', function() {
          //debugger;
          if (target.hasClass('active')) {
            target.removeClass('active');
          } else {
            target.addClass('active');
          }
          that.value_changed(filter_pane);
        });
      } else if (target.hasClass('js-nav-button')) {
        target.on('click', function() {
          target.addClass('active');
          that.value_changed(filter_pane);
        });
      } else if (target.is('button')) {
        target.on('click', function() {
          if (target.hasClass('active')) {
            target.removeClass('active');
          } else {
            target.addClass('active');
          }
          that.value_changed(filter_pane);
        });
      }
    });
  }

  _createClass(FilterPane, [{
    key: "value_changed",

    value: function value_changed(filter_pane) {
      var params = {};
      filter_pane.find('.js-filter-element').each( function() {
        var name = $(this).data('param-name');
        var val = '';
        if ($(this).hasClass('js-toggle-nav-button')) {
          if ($(this).hasClass('active')) {
            val = 'true';
          } else {
            val = 'false';
          }
        } else if ($(this).hasClass('js-nav-button')) {
          if (!$(this).hasClass('active')) {
            return;
          }
          val = $(this).data('param-value');
        } else if ($(this).is('button')) {
          val = $(this).hasClass('active');
          val = "".concat(val);
        } else {
          val = $(this).val();
          if ($.isArray(val)) {
            val = val.join();
          }
        }
        return params[name] = val;
      });
      var url = filter_pane.data('href');
      var formatted_params = jQuery.param(params);
      url = "".concat(url, "?").concat(formatted_params);
      return $.ajax({
        dataType: "script",
        type: "GET",
        url: url
      });
    }
  }]);

  return FilterPane;
}();


$(document).on("turbolinks:load", function() {
  return new App.FilterPane($(".js-filter-pane"));
});