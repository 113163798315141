/**
 * controll checkbox selection.
 * values:
 *   selector: selector for defining which checkboxes are controlled
 * functions:
 *   selectAll: to select all checkboxes
*/
// Example
// <div class="form-inputs"
//      data-controller="input--checkbox-selector"
//      data-input--checkbox-selector-selector-value="input[data-pep7]">
//   <div class="btn btn-light" data-action="click->input--checkbox-selector#selectAll">Pep7 selektieren</div>
//   <%= f.input computed_attribute, label: label, input_html: { data: { pep7: true }} %>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bookings" ]
  static values = {
    selector: String
  }

  connect() {
    //console.log('cs')
  }

  selectAll() {
    const elems = document.querySelectorAll(this.selectorValue);
    if (elems) {
      //console.log('to select', elems.length)
      for (let i = 0; i < elems.length; i++) {
        const element = elems[i];
        element.checked = true
      }
    } else {
      console.log('Warning! nothing selected!')
    }
  }
}