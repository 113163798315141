// to go to an url from a select (from app.go_to_url.coffee)
import url_helper from './url'

const ARMED_TAG = '_go_to_url_armed_'

function onChange(selected) {
  if (!selected) { return; }

  var url = selected.data('url');
  var method = selected.data('method');
  if (!method) { method = 'GET'; }
  if (!url) { return; }
  var remote = selected.data('remote');
  var modal = selected.data('modal');

  console.log('go to url triggered')
  if (remote) {
    url_helper.callScript(url, { method })
  } else if (modal) {
    $('.modal-backdrop').remove();
    modal = new App.Modal(url);
  } else if (method === 'POST') {
    url_helper.callUrl(url, { method })
  } else {
    if (method !== 'GET') { alert('not implemented'); }
    Turbolinks.visit(url);
  }
}

function init(selector = $('.js-go_to_url')) {
  const nodes = selector.not(`.${ARMED_TAG}`)
  //if (nodes.length) { console.log('go to url armed') }

  nodes.on('change', e => {
    onChange($("option:selected", e.currentTarget))
  })
  nodes.addClass(ARMED_TAG)
  // just for seeing the result, can be removed
  return nodes.length
}

App.GoToUrl = {
  init,
  triggerChange: onChange
}