App.UrlHelper = {
  updateParameter: (param, val, {params = window.location.search, url} = {}) => {
    const paramsString = url ? url.split('?')[1] : params;
     const searchParams = new URLSearchParams(paramsString);
    if (val === undefined  || val === null) {
      searchParams.delete(param);
    } else {
      searchParams.set(param, val);
    }
    if (url) {
      return url.split('?')[0] + '?' + searchParams.toString()
    } else {
      return searchParams.toString();
    }
  },
  callScript: (url, options = {}) => {
    return App.UrlHelper.callUrl(url, {...options, dataType: "script"});
  },
  loadHTML: (url, {method = 'GET'} = {}) => {
    return $.ajax({dataType: "html", type: method, url: url})
  },
  callUrl: (url, {method = 'GET', dataType = undefined, data = undefined, ...others} = {}) => {
    return $.ajax({...others, type: method, dataType,  url, data});
  },
  goToPage: (page) => {
    //mimicking previous implementation, not sure if this happens
    if (page && page != '') {
      Turbolinks.visit(page);
    }
  },
  goToPageInNewTab: (page) => {
    //mimicking previous implementation, not sure if this happens
    if (page && page != '') {
      window.open(page, '_blank');
    }
  },

  // this prevents all ajax calls from happening if calling the same url within a given time
  initAjaxMulticallPrevention: () => {
    // already initialized
    if (App.Callers) { return; }

    App.Callers = new Map();
    function key_from_settings({url, data} = {}) {
      return `${url}-${data}`
    }

    const MIN_MS_INTERVAL_BETWEEN_AJAX_CALLS = 1000;
    const MAX_OBJECTS = 100;
    $( document ).ajaxSend(function( event, jqxhr, settings ) {
      const key = key_from_settings(settings);
      const last_call = App.Callers.get(key);
      const now = new Date();

      if (last_call && now - last_call < MIN_MS_INTERVAL_BETWEEN_AJAX_CALLS) {
        // we could count this and inform the user, they don't need to double click, if too many happened.
        console.log('aborting call')
        jqxhr.abort('Einmal klicken reicht!');
      }
      if (App.Callers.size > MAX_OBJECTS) {
        // to limit the size if the user never refreshes...
        console.log('flushing App.Callers')
        App.Callers = new Map();
      }
      App.Callers.set(key, now);
    });
  }
}


export default App.UrlHelper;
