// show check-list entries of the selected task

import { Controller } from "stimulus"
export default class extends Controller {
  static values = {
    displayId: String
  }

  connect() {
    //this assures the select get instantiated
    App.init();
    const _ctrl = this;
    $(this.element).on('select2:select', function () {
      // there seem not to be any proble, when calling it directly
      // Event should be supported by most brosers, but still

      //let event = new Event('change', { bubbles: true }) // fire a native event
      //this.dispatchEvent(event);
      // which will required this in the select:
      // 'data-action':'change->check-list#selectionUpdate'
      _ctrl.selectionUpdate();
    });

    this.selectionUpdate()
  }

  get selection() {
    return $(this.element).select2('data');
  }

  selectionUpdate() {
    const selection = this.selection && this.selection[0]
    const url = selection && $(selection.element).data('time-tracking-leaf-url')
    if (url) {
      $(`#${this.displayIdValue}`).spin({position:'relative'})
      // this should be used as a helper somewhere else.
      App.UrlHelper.loadHTML(url).then( html => {
        // NOTE: fetch does not seem to work properly in previous Safari versions
        // trying to check the user logged out and we got redirected to login in jquery
        const is_complete_page = html.substring(0,50).toLowerCase().indexOf('html') !== -1

        if(is_complete_page) {
          // we asume is the login page. Reloadd page so we follow the 302
          location.reload();
        } else {
          $(`#${this.displayIdValue}`).html(html)
          App.init();
          $(`#${this.displayIdValue}`).spin(false)
        }
      })
    } else {
      $(`#${this.displayIdValue}`).html('')
    }
  }
}
