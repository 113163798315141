const faviconEl = document.querySelector('link[rel="icon"]')

const mediaQuery = window.matchMedia('(prefers-color-scheme: light)')
mediaQuery.addEventListener('change', themeChange)

function themeChange(event) {
    if (event.matches) {
      faviconEl.setAttribute("href", "projo_favicon_dark_32.png")
    } else {
      faviconEl.setAttribute("href", "projo_favicon_light_32.png")
    }
  }