/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Popover from './popover'

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

const MODAL = `
<div class="modal" id="mainModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h4 class="modal-title" id="mainModalLabel"></h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" id="mainModalBody"> </div>
      <div class="modal-footer"> <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button> </div>
    </div>
  </div>
</div>
`

function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;

    if ("value" in descriptor)
      descriptor.writable = true;

    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps)
    _defineProperties(Constructor.prototype, protoProps);

  if (staticProps)
    _defineProperties(Constructor, staticProps);

  return Constructor;
}

// https://github.com/glaucocustodio/remote_bootstrap_modal

(function() {
  var Cls = (App.Modal = function() {
    "use strict";
    _createClass(Modal, null, [{
      key: "close",

      value: function close() {
        var closeBtn = $("#mainModal button.btn-close");
        if (closeBtn) { return closeBtn.click(); }
      }
    }, {
      key: "show",

      value: function show(message, title, { html=false }={}) {
        if (title == null) { title = 'Info'; }
        var e = $('#modal-holder').html(MODAL);
        e.find('#mainModalLabel').text(title);
        e.find('#mainModalBody')[html ? 'html' : 'text'](message);
        new bootstrap.Modal('.modal', {backdrop: 'static'}).show();
        //return e.find('.modal').modal({backdrop: 'static'});
      }
    }]);

    function Modal(url, reload_page_after_close, call_url_after_close, modal_anchor) {
      _classCallCheck(this, Modal);
      var submit_on_close;
      var that = this;
      Popover.hide();

      // add data-submit-on-close on modal link to force submit e.g. data: {submit_on_close: true}
      if (modal_anchor) {
        submit_on_close = $(modal_anchor).data('submit-on-close');
        reload_page_after_close = $(modal_anchor).data('reload-page-after-close');
      }
      $.get(
        {
          url: url,
          beforeSend: xhr => xhr.setRequestHeader('X-Projo-RenderType', 'modal')
        },
        function(data) {
          // if we get a redirect instead we'll get the final page here. We can handle it differently if needed.
          $('#modal-holder').html(data).find('.modal').modal({backdrop: 'static'});
          return new App.LoadJsForModal;
        }
      );
      if (reload_page_after_close) {
        $('#modal-holder').on('hidden.bs.modal', function() {
          App.Spinner.show()
          return location.reload();
        });
      }
      if (call_url_after_close) {
        $('#modal-holder').on('hidden.bs.modal', function() {
          return $.ajax({
            dataType: "script",
            type: "GET",
            url: call_url_after_close});
        });
      }
      if (submit_on_close) {
        $('#modal-holder').on('hidden.bs.modal', function() {
          const form = $("#mainModal form");
          if (form && !form.data('do_not_submit')) { form.submit(); }
      });
      }
    }
    return Modal;
  }());
  return Cls;
})();

// very bad designn, need to refactor this. The modal doesn't need to know about this.
$(document).on('click', 'a[data-modal=true]',  function() {
  var url = $(this).attr('href');
  var reload_page_after_close = $(this).data('reload-page-after-close');
  var call_url_after_close = $(this).data('call-url-after-close');
  if ($('body').hasClass('modal-open')) {
    // if modals is opened from a modal
    $('#modal-holder').find('.modal').modal('hide');
  }
  if ($(this).hasClass('js-enrich-url-by-booking-ids')) {
    var checked_boxes = $('.booking_check_box').filter('input:checked');
    var unchecked_boxes = $('.booking_check_box').filter('input:not(:checked)');
    var bookings = checked_boxes.map((_i, check_box) => $(check_box).data('bookingId')).get();

    url = "".concat(url, "&booking_ids=").concat(bookings);
  }

  new App.Modal(url, reload_page_after_close, call_url_after_close, this);
  return false;
});

$(document).on("turbolinks:load", function() {
  return $(document).on('ajax:success', 'form[data-modal]', function(event, data, status, xhr){
    var url = xhr.getResponseHeader('Location');
    // in test environment, the Location is not 'no_modal', but 'http://localhost:3001/hoai/no_modal'
    if (url && ((url.indexOf("ajax_in_modal") >= 0) || (url.indexOf("no_modal") >= 0))) {
//    if url && (url == 'ajax_in_modal' || url == 'no_modal')
      // ok
    } else if (url) {
      // Redirect to url

      window.location = url;
      // Turbolinks.visit url  better? - never tried
    } else {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();

      // Replace old modal with new one
      $('#modal-holder').html(data).
      find('.modal').modal({backdrop: 'static'});
      new App.LoadJsForModal;
    }
    return false;
  });
});

// DOES NOT WORK
//$(document).on "turbolinks:load", ->
//  $(document).keypress (e) ->
//    console.log e
//    if (e.keyCode == 27)
//      $('#modal-holder').find('.modal').modal('hide')

App.LoadJsForModal = function LoadJsForModal() {
  "use strict";
  _classCallCheck(this, LoadJsForModal);
  App.init();
  var datepicker = new App.Datepicker($(".js-datepicker"));
  App.GoToUrl.init($(".modal .js-go_to_url"));
  var best_in_place = new App.BestInPlace($(".best_in_place"));
  var daterangepicker = new App.DateRangePicker($(".js-daterange"));
  App.Typeahead.init();
  $('.js-slider').each((_i, slider_el) => {
    new App.Slider($(slider_el))
  })
  $('#mainModal').draggable({handle: '#modalHandle'});
};
