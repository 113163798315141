/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import moment from 'moment';
import {} from 'bootstrap-datepicker';
import {} from 'bootstrap-daterangepicker';
// add all required languages here
import {} from 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de';
require("bootstrap-daterangepicker/daterangepicker.css")
require("bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css")


App.Datepicker = function Datepicker(el) {
  // don't show autocompletion if we use the date picker widget
  $(el).attr('autocomplete', 'off');
  el.datepicker({
    language: 'de',
    format: 'dd.mm.yyyy',
    autoclose: true,
    todayHighlight: true,
    minViewMode: el.data('min-view-mode') || 0,
    todayBtn: 'linked',
    defaultViewDate: ((el.data('default-view-date') ? new Date( el.data('default-view-date')) : undefined))
    });
  $('.js-write-date').on('changeDate', function() {
    var val = $(".js-datepicker").datepicker('getFormattedDate');
    return $('#time_tracking_date_new_date').val($(".js-datepicker").datepicker('getFormattedDate'));
  });
};

App.DateRangePicker = function DateRangePicker(el) {
  // don't show autocompletion if we use the date picker widget
  $(el).attr('autocomplete', 'off');
  moment.locale('de');

  const options = {
    'autoApply': true,
    'locale': {
      'format': 'DD.MM.YYYY',
      'firstDay':1,
      'applyLabel':'Ok',
      'cancelLabel':'Abbrechen'
    }
  };
  // should we use custom ranges?
  // options['ranges']['next 6 Months'] = [moment(), moment().add(6, 'month').endOf('month')] if false
  el.daterangepicker(options);
};


App.Datepicker.init = () => {
  App.Datepicker($(".js-datepicker"))
  App.DateRangePicker($(".js-daterange"));
}

//$(document).on("turbolinks:load", App.Datepicker.init);