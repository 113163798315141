/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.App) { window.App = {}; }

App.init = function() {
  App.clearTooltips();

  // These are already idempotent, and may be replaced by a call to App.init.
  App.FreezeTable.init();
  App.Select2.init();
  App.Select2CreateTag.init();
  App.BestInPlace.init();
  App.TableSortable.init();
  App.DataTable.init();
  App.SelectableCell.init();
  App.ClickInTable.init();
  App.Colorpicker.init();
  App.Clipboard.init();
  App.BgJob.init();
  App.GoToUrl.init();
  App.Datepicker.init();
  App.Popover.init();
  App.Tooltip.init();


  $('body').on('click', function(e) {
    if (($(e.target).data('toggle') !== 'ajax-popover') && ($(e.target).parents('.popover.in').length.zero != null)) {
        return $('[data-bs-toggle="ajax-popover"]').popover('hide');
      }
  });

  const block_actions_tag = 'block-further-actions';
  $('a').on('click', function(e) {
    const a = $(e.currentTarget);
    if (a.data(block_actions_tag) !== undefined) {
      $('#spinning_center').spin();
      $('#blockage').show();
      // and preventing the link to work again (too much?)
      a.data(block_actions_tag, undefined);
      a.on('click', e => false);
    }
  });
  $('#blockage').hide();
  $('#spinning_center').spin(false);

  App.UrlHelper.initAjaxMulticallPrevention();

  // asure that, if we use FreezeTable, it get refreshed (when a js changes part of the table, etc.)
  App.FreezeTable.refresh();
};

// this should remove the effects apply, to prepare the page for caching when leaving
// the current page (turbolinks)
App.clearTooltips = () => {
  App.Popover.hide();
  App.Tooltip.hide();
}
App.clean = () => {
  App.clearTooltips();
  App.BestInPlace.clean();
  App.FreezeTable.clean();
  App.DataTable.clean();
  App.Modal.close();
}


$(document).on("turbolinks:load", () => { App.clean(); App.init() });
// this prevents the save html (shown before the page is loaded if going back)
$(document).on("turbolinks:before-cache", () => App.clean());
