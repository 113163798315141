// This example controller works with specially annotated HTML like:
//
/**
 * shows a confirm message only if certain value exceeds a threshold
 * we cannot use the same data-confirm attributes, since we cannot easily tunt it of
 * so this uses stimuls prefixes (data-<controller-name>-<attribute-name>-value )
 *
 *  <a href='#should_not_ask' data-controller="conditional-confirm"
  data-conditional-confirm-amount-value="<%= lower_value %>"
  data-conditional-confirm-max-amount-value="<%= limit %>"
  data-conditional-confirm-body-value="you sure?"
  data-conditional-confirm-title-value="A title"
  class="btn btn-light">
  This is <%= lower_value %> and is not checked
</a> */

import { Controller } from "stimulus"
// DEPRECATED: not used anymore, if not required remove
export default class extends Controller {
  static values = {
    amount: Number,     // the current number to be evaluated
    maxAmount: Number,  // the maximuma after which a confirmation is required (defaults to 1000)
    body: String,       // the message body, like data-confirm
    title: String       // the message title, like data-confirm-title
  }

  connect() {
    // we can use text defaults if we place them before this is loaded.
    this.amountValue ||= 0
    this.maxAmountValue ||= 1000

    if (this.amountValue > this.maxAmountValue) {
      this.arm()
      this.isArmed = true;
    }
    // dissarming is tricky, since the event handler is already set.
    // it might involve patching $.rails.allowAction as twitter-bootstrap-rails-confirm does
  }

  arm() {
    this.element.dataset.confirm = this.bodyValue
    this.element.dataset.confirmTitle = this.titleValue
  }
}
