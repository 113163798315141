import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "hideable" ]
    
  checked() {
    this.hideableTargets.forEach(el => {
      el.hidden = !el.hidden
      
      // to avoid code duplication of painting / unpainting columns, a new controller "PaintController" could be created, used via event dispatching from here and from select_column_dropdown_controller.js
      if(el.hidden == true){
        var dropwdown = document.getElementById('best_in_place_office_cost_structure_template_2_column_hs');
        var upload_table = document.getElementById('upload-table');
        upload_table.rows.forEach((tr, index) => {
          if(tr.cells.length === upload_table.rows[0].cells.length){
            var td = tr.cells[dropwdown.dataset.bipValue];
            td.removeAttribute("style");
          }
        });
      }
      if(el.hidden == false){
        var dropwdown = document.getElementById('best_in_place_office_cost_structure_template_2_column_hs');
        var upload_table = document.getElementById('upload-table');
        upload_table.rows.forEach((tr, index) => {
          if(tr.cells.length === upload_table.rows[0].cells.length){
            var td = tr.cells[dropwdown.dataset.bipValue];
            td.setAttribute("style", "background-color: #FFDB6B;");
          }
        });
      }
    });
  }
}
