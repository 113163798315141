/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;

    if ("value" in descriptor)
      descriptor.writable = true;

    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps)
    _defineProperties(Constructor.prototype, protoProps);

  if (staticProps)
    _defineProperties(Constructor, staticProps);

  return Constructor;
}

App.Timer = function() {
  "use strict";
  function Timer(el) {
    this.run = this.run.bind(this);
    this.rerun = this.rerun.bind(this);
    this.set_start_time = this.set_start_time.bind(this);
    this.stop = this.stop.bind(this);
    this.displayBooking = this.displayBooking.bind(this);
    this.el = el;
    this.set_start_time();
  }

  _createClass(Timer, [{
    key: "run",

    value: function run() {
      var that = this;
      var duration =  (Date.now() - this.start_time);
      duration = duration/1000;
      var timer_str = that.displayBooking(duration);

      return that.timer = setInterval((function() {
        return that.displayBooking(++duration);
      }), 1000);
    }
  }, {
    key: "rerun",

    value: function rerun() {
      this.stop();
      this.set_start_time();
      return this.run();
    }
  }, {
    key: "set_start_time",

    value: function set_start_time() {
      var raw_start_time = $(".js-timer").data("start-time");
      return this.start_time = Date.parse(raw_start_time);
    }
  }, {
    key: "stop",

    value: function stop() {
      return clearInterval(this.timer);
    }
  }, {
    key: "displayBooking",

    value: function displayBooking(duration) {
      var days    = parseInt(duration / (60*60*24), 10);
      var hours   = parseInt((duration / (60*60)) - (days * 24), 10);
      var minutes = parseInt((duration / 60) - (days * 24 * 60) - (hours * 60), 10);
      var seconds = parseInt(duration - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60), 10);
      var days_str = days < 10 ? '0' + days : days;
      var hours_str = hours < 10 ? '0' + hours : hours;
      var minutes_str = minutes < 10 ? '0' + minutes : minutes;
      var seconds_str = seconds < 10 ? '0' + seconds : seconds;
      var duration_str = days > 0 ? days_str + ':' : '';
      duration_str = duration_str + hours_str + ':' + minutes_str + ':' + seconds_str;
      return $('.js-timer').text(duration_str);
    }
  }]);

  return Timer;
}();

$(document).on("turbolinks:load", function() {
  if (!($(".js-timer").length > 0)) { return; }
  if (App.timer !== undefined) {
    // App.timer.stop()
  } else {
    App.timer = new App.Timer($(".js-timer"));
    return App.timer.run();
  }
});