function init() {
  const tables = [];
  $('table.selectable_cells').not('.selectable_cells_armed').each((i, t)=>{
    const table = $(t);
    if (!table.parent().is('[class^="clone"]')) {
      // skip clones from freeze table
      table.addClass('selectable_cell_armed');
      tables.push(arm_table(table));
    }
  });

  $(document).keydown(function(e){
    if (e.keyCode == 27) {
      tables.forEach(table => table.deselect_all())
    }
  });
} // init()

function arm_table(table) {
  //console.log('init table');
  const table_refresh_url = table.data('href');
  let selection = null

  function select_cell(cell) {
    deselect_all();
    selection = cell;
    cell.addClass('selectable_cell_hover selectable_cell_hover_start selectable_cell_hover_end')
  }

  function deselect_all() {
    if (selection) {
      remove_highligh_cells();
      selection = null;
    }
  }

  function same_row(cell1, cell2) {
    return cell1 && cell2 && cell1.data('row-id') == cell2.data('row-id');
  }

  function highligh_cells_in_row(cell1, cell2) {
    cells = selected_cells(cell1, cell2);

    remove_highligh_cells();
    cells.addClass('selectable_cell_hover')
    cells.first().addClass('selectable_cell_hover_start')
    cells.last().addClass('selectable_cell_hover_end')
  }
  function remove_highligh_cells() {
    table.find('td').removeClass('selectable_cell_hover selectable_cell_hover_start selectable_cell_hover_end')
  }

  function selected_cells(cell1, cell2) {
    const row = cell1.parent().index() + 1;
    let from = cell1.index();
    let to = cell2.index();
    if (from > to) { [from, to] = [to, from] }
    return table.find(`tr:nth-child(${row}) td`).slice(from, to + 1);
  }

  table.find('.selectable_cell').not('.selectable_cell_armed').each((i, e) =>{
    const cell = $(e);
    cell.addClass('selectable_cell_armed');
    cell.on('click', (e)=>{
      if (selection && same_row(selection, cell)) {
        let all_were_selected = true;
        const ids = [];
        selected_cells(selection, cell).each((_i, e)=> {
          all_were_selected = all_were_selected && $(e).data('selected')
          ids.push($(e).data('column-id'));
        });

        const selected_range = {
          ids: ids,
          row_id: cell.data('row-id'),
          select: !all_were_selected
        }
        deselect_all();
        console.log('call backend with', selected_range)
        const modal = cell.data('modal');
        const url = cell.data('href') || table_refresh_url;
        if (modal) {
          // modals are in get, we need to add
          const changed_url = new URL(url);
          for (var key in selected_range) {
            changed_url.searchParams.set(key, selected_range[key])
          }
          new App.Modal(changed_url, cell.data('reload-page-after-close'), cell.data('call-url-after-close'));
        } else {
          $.ajax({
            url: cell.data('href') || table_refresh_url,
            dataType: "script",
            type: "POST",
            data: selected_range,
          });
        }
      } else {
        deselect_all();
        select_cell(cell);
      }
    }); // click

    cell.on('mouseover', ()=>{
      if (selection && same_row(selection, cell)) {
        highligh_cells_in_row(selection, cell)
      }
    });

  });


  return {
    deselect_all
  }
} // arm_table


App.SelectableCell = {
  init
}
