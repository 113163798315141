/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Cls = (App.BestInPlace = class BestInPlace {
  static initClass() {
    this.prototype.armed_tag = '_bip_armed_';
  }
  static init() {
    new (this)($(".best_in_place"));
    errorHandler();
  }
  static clean() {
    $(".best_in_place._bip_armed_").removeClass('_bip_armed_');
  }
  constructor(el) {
    this.el = el;
    this.el = this.el.not(`.${this.armed_tag}`);
    if (!this.el.length) { return; }

    this.el.addClass(this.armed_tag);

    this.el.best_in_place();

    this.el.on("ajax:success", function(event, data) {
      $(this).closest('span').effect('highlight', {color: 'green'}, 500);
      const url = $(this).data("update-after-bip-url");
      if (url) {
        $.ajax({
          dataType: "script",
          type: "GET",
          url,
          success(data, textStatus, jqXHR) {
            return $(document).trigger('bip:reload');
          }
        });
      }
      if ($(this).data("reload-page")) {
        return location.reload();
      } else {
        // if in a table, a new content might change the table size, so refresh the freezed headers
        // TODO: also required when bip is *in* the frozen header, but updating it does not work
        // this implies also updating the real bip,
        App.FreezeTable.refresh();
      }
  });

    $(document).trigger('bip:load');
  }
});

Cls.initClass();

function mergeErrorMsgs(errors) {
  var errors_string = '';
  $.each(errors, (index, value) => {
    if (typeof value === "object") {
      //$.each(value, (i, v) => { errors_string += i[0].toUpperCase() + i.slice(1) + " " + v + ". " });
      errors_string += index + " " + value.toString();
    } else {
      errors_string += value;
    }
    errors_string += '. '
  });
  return errors_string;
}

function errorIcon(errorString) {
  const icon = $('<span></span>')
  icon.attr('title', errorString);
  icon.addClass('error_icon px-1 glyphicon glyphicon-exclamation-sign text-danger')
  icon.tooltip({
    trigger : 'hover'
  });
  return icon;
}

function handleHiding(icon) {
  const hideInMillis = 5000;
  const fadeForMillis = 500;
  setTimeout(() => {
    icon.fadeOut(fadeForMillis, icon.remove);
    icon.tooltip('hide');
  }, hideInMillis)
}

function removePreviousMessage(node) {
  const icon = node.next('.error_icon');
  icon.tooltip('hide');
  icon.remove();
}

function errorHandler() {
  $(document).on('best_in_place:error', function(event, request, error) {
    const node = $(event.target);
    let msg;
    try {
      msg = JSON.parse(request.responseText);
    } catch {
      // should we have a general error message? The icon should be enough.
      msg = [request.responseText || request.statusText]
    }
    const errors = mergeErrorMsgs(msg);
    const icon = errorIcon(errors);

    removePreviousMessage(node);
    icon.insertAfter(node);

    handleHiding(icon)
  });
}

