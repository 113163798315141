import { Controller } from 'stimulus';

// This controller is used to manage the state of buttons which trigger and wait for
// background jobs. It will disable the button and poll the server for the status of the job.
export default class extends Controller {
  connect() {
    this.readyHTML = this.element.innerHTML;
    this.enabledClass = this.element.classList.value;
    this.state = this.element.dataset.state;
    this.redirectTo = this.element.dataset.redirectTo;

    if(this.state == 'disabled') {
      this.disable();
      this.maybePoll();
    }
  }

  maybePoll() {
    if (this.state == 'disabled' && this.element.dataset.statusRoute != null) {
      this.startPolling();
    }
  }

  disable() {
    this.state = 'disabled';
    this.element.innerHTML = this.element.dataset.disabledHtml;
    this.element.classList = this.element.dataset.disabledClass;
    this.maybePoll();
  }

  enable() {
    this.state = 'enabled';
    this.element.innerHTML = this.readyHTML;
    this.element.classList = this.enabledClass;
  }

  toggle() {
    if (this.state == 'disabled') {
      this.enable();
    } else {
      this.disable();
    }
  }

  startPolling() {
    this.interval = setInterval(() => {
      fetch(this.element.dataset.statusRoute, {credentials: 'same-origin'})
        .then(response => response.json())
        .then(data => {
          if (data.status == 'done') {
            clearInterval(this.interval);
            if(this.redirectTo != null) {
              window.location.href = this.redirectTo;
            } else {
              this.enable();
            }
          }
        });
    }, 5000);
  }

}
