// Example:
// <div data-controller="truncate" data-truncate-max-height-value="100" data-action="click->truncate#show">
//   this will be truncated
// </div>
//

import { Controller } from "stimulus"
import './truncate.css'

const TRUNCATE_CLASS = 'truncate'
export default class extends Controller {

  static values = {
    maxHeight: Number
  }

  connect() {
    /* we can try to approximatee lines, but this is too imprecise
    const lineHeight = parseFloat(window.getComputedStyle(this.element).lineHeight)
    const lines = Math.ceil(this.element.offsetHeight / lineHeight)
    this.maxLinesValue ||= 3
    */
    this.maxHeightValue ||= 50
    if (this.element.offsetHeight > this.maxHeightValue) {
      this.element.classList.add(TRUNCATE_CLASS)
      this.element.style.maxHeight = `${this.maxHeightValue}px`
    }
    //class="truncate" onclick="this.classList.remove('truncate')"

  }

  show() {
    this.element.classList.remove(TRUNCATE_CLASS)
    this.element.style.maxHeight = 'none'
    // not tested, but this might be required if this is within such a table
    App.FreezeTable.refresh()
  }
}