/*
<div data-controller="extra-config" style="display:none">
   ...
</div>
*/
import { Controller } from "stimulus"

function toggle_icon(icon) {
  return `
  <div onclick="$(this).next().show();$(this).hide();" style="cursor:pointer">
    <span class='glyphicon glyphicon-${icon}'></span>
  </div>`
}

export default class extends Controller {
  static values = {
    icon: String,
  }

  connect() {
    //add all children to a container for simpler handling
    const container = $('<div/>')
    container.hide();
    $(this.element).children().detach().appendTo(container);
    this.iconValue = this.iconValue || 'wrench'
    $(this.element).append(toggle_icon(this.iconValue));
    $(this.element).append(container);

    $(this.element).show();
  }

}