/*
<input type="text" data-controller="focus" >
*/
import { Controller } from "stimulus"
const CONTROLLER_NAME = 'focus'

export default class extends Controller {
  connect() {
    if (this.shouldGainFocus()) { this.element.focus() }
  }

  shouldGainFocus() {
    const currentFocusedElement = document.activeElement;
    if (currentFocusedElement && currentFocusedElement.dataset.controller == CONTROLLER_NAME) {
      // other element using this controller. Sort by tabindex
      const otherIndex = currentFocusedElement.tabindex || 0;
      const thisIndex = this.element.tabindex || 0;
      return thisIndex < otherIndex;
    } else {
      return true;
    }
  }
}
