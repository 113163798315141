/*
activation: with the class "data-table" in a table object

html attributes:
  id: needs to be uniq, but if not set, the path of the page will be used (so one table per page is ok)
      There's a check which will complain if it's not the case.

html data attributes:
  in table:
    'preserve-state' Will store the state (ordering, etc) so when the user comes back it will be displayed as expected
  in td:
    'data-order' used for defining how to sort

Example:
<table class="table data-table" data-preserve-state="true">
  ...
  <tr><td data-order="123123123">Monday, 12th January 2026</td></tr>
</table>
*/

//ugly!
//
// Currently nowhere used!!!
window.JSZip = require('jszip');
require('datatables.net-bs5');
require("datatables.net-bs5/css/dataTables.bootstrap5.min.css")
require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.html5');

const ID = 'data-table'
const REGISTRY = {};

function init() {
  $(`table.${ID}`).not(`.${ID}-armed`).each((_i, t) => {
    const table = $(t);
    if (!table.attr('id')) {
      table.attr('id', window.location.pathname);
    }

    if (REGISTRY[table.attr('id')]) {
      throw `Table with id '${table.attr('id')}' is not uniq!`;
    }
    table.addClass(`${ID}-armed`);

    let options = {
      id: table.attr('id'),
      //https://datatables.net/reference/option/dom
      dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 text-center'B><'col-sm-12 col-md-4'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      //https://datatables.net/reference/option/buttons.buttons.className
      buttons: [
        { extend: 'copy', className: 'btn btn-light btn-sm' },
        { extend: 'csv', className: 'btn btn-light btn-sm' },
        { extend: 'excel', className: 'btn btn-light btn-sm' }
      ],
      language: {
        url : "/localisation/de/data-tables.json"
      }
    };

    if (table.data('preserve-state')) {
      options = {
        ...options,
        stateSave: true,
        stateSaveCallback: function(settings,data) {
          localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
          },
        stateLoadCallback: function(settings) {
          return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )
        }
      }
    }

    REGISTRY[table.attr('id')] = table.DataTable(options);

  });
}

function clean() {
  $(`.${ID}-armed`).each((_i, t) => {
    const table = $(t);
    REGISTRY[table.attr('id')].destroy();
    delete REGISTRY[table.attr('id')];

    // for a better ux, remove the table and add spinner before caching into Turbolinks
    // this is shown while going back and waiting for the real page to load.
    table.remove();
    $('#spinning_center').spin();
  });
}
App.DataTable = {
  init,
  clean
}