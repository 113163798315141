// Handles a url that can be manipulatetd with different filters
// e.g. used in ics calendar generation

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]
  static values = {
    url: String,
    redirect: Boolean
  }

  connect() {
    this.show()
  }

  filter(event) {
    const param = $(event.currentTarget).data('param')
    const val = $(event.currentTarget).data('value')

    this.urlValue = App.UrlHelper.updateParameter(param, val, {url: this.urlValue})
    this.show()
    this.redirect()
  }

  removeFilter(event) {
    const param = $(event.currentTarget).data('param')
    this.urlValue = App.UrlHelper.updateParameter(param, undefined, {url: this.urlValue})
    this.show()
  }

  show() {
    if (this.hasOutputTarget) {
      this.outputTarget.innerText = this.urlValue
    }

  }
  redirect() {
    if (this.redirectValue) {
      //console.log(this.urlValue)
      Turbolinks.visit(this.urlValue)
    }
  }
}
