import { Controller } from "stimulus";

export default class TrixController extends Controller {
  static values = {
    enableButtons: Array,
  };
  static UNUSED_TOOLBAR_CLASSES = [".trix-button-group--file-tools"];

  static DEFAULT_UNUSED_TOOLBAR_BUTTONS = [
    "icon-link",
    "icon-strike",
    "icon-code",
    "icon-quote",
    "icon-italic",
  ];

  static addUnderlineTag() {
    Trix.config.textAttributes.underline = {
      tagName: "u",
      style: { textDecoration: "underline" },
      inheritable: true,
      parser: function (element) {
        let style = window.getComputedStyle(element);
        return style.textDecoration === "underline";
      },
    };
  }

  static underlineButton() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline"><sub>Underline</sub></button>';
  }

  handleTrixInitialize(event) {
    TrixController.addUnderlineTag();

    if (document.querySelector(".trix-button--icon-underline") === null) {
      document
        .querySelector(".trix-button-group--text-tools")
        .insertAdjacentHTML("beforeend", TrixController.underlineButton());
    }

    TrixController.UNUSED_TOOLBAR_CLASSES.forEach((cls) => {
      const element = document.querySelector(cls);
      if (!!element) {
        element.remove();
      }
    });

    const unusedButtons = TrixController.DEFAULT_UNUSED_TOOLBAR_BUTTONS.filter(
      (button) => {
        return this.enableButtonsValue.indexOf(button) === -1;
      },
    );

    unusedButtons.forEach((button) => {
      const element = document.querySelector(`.trix-button--${button}`);
      if (!!element) {
        element.remove();
      }
    });
  }

  handleTrixFileAccept(event) {
    // disable file uploads
    event.preventDefault();
  }

  connect() {
    addEventListener(
      "trix-initialize",
      (e) => {
        this.handleTrixInitialize(e);
      },
      true,
    );
    addEventListener("trix-file-accept", this.handleTrixFileAccept, true);
  }

  disconnect() {
    removeEventListener("trix-initialize", this.handleTrixInitialize);
    removeEventListener("trix-file-accept", this.handleTrixFileAccept);
  }
}
