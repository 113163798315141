import Slider from 'bootstrap-slider';

App.Slider = class {
  static init() {
    $('.js-slider').each((i, elem) => new App.Slider($(elem)) )
  }
  constructor(el) {
    el.slider().on('slideStop', event => {
      const that = $(event.target);
      const value = event.value;
      const url = that.data('url');
      const column = that.data('column');
      const data = {};
      data[column] = value;
      $.ajax({
        url: url,
        type: 'PATCH',
        dataType: 'JSON',
        data: data,
        complete: data => {
          let after_update_url = that.data("after-update-url");
          if (after_update_url) {
            $.ajax({
              dataType: "script",
              type: "GET",
              async: true,
              url: after_update_url
            })
          }
        }
      })
    });
  }
}

$(document).on("turbolinks:load", () => {
  App.Slider.init()
});