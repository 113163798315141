import { Controller } from 'stimulus';
import { delegate } from 'jquery-events-to-dom-events'

delegate('ajax:success', ['event', 'data', 'status', 'xhr'])

export default class extends Controller {
  static targets = ['feedback', 'scrollingEnabled']
  migrationFileId = null
  polling = false

  connect() {
    this.maybeStart()
  }

  start(event) {
    this.migrationFileId = event.srcElement.dataset["migrationFileId"]
    this.startPolling()
  }

  maybeStart() {
    let id = location.href.split('/').slice(-1)
    if ( isNaN(id) ) {
      return
    }
    this.startPolling()
  }

  startPolling() {
    this.spinner()
    this.polling = true
    setInterval((()=> this.poll()), 1000)
  }

  spinner() {
    this.feedbackTarget.innerHTML = '<span class="spinner-border spinner-border-sm"></span>'
    this.feedbackTarget.classList.remove("alert")
    this.feedbackTarget.classList.remove("alert-success")
  }

  update(html) {
    if (this.last_html != html) {

      // If we disable updates, just return
      if (this.scrollingEnabledTarget.checked) {
        // Update the html with new response
        this.feedbackTarget.innerHTML = html

        // Scroll the scrollable terminal div to the bottom
        this.feedbackTarget.getElementsByClassName('terminal')[0].scrollTop = this.feedbackTarget.getElementsByClassName('terminal')[0].scrollHeight;
      }
    }

    // Save last response to avoid re-rendering when same
    this.last_html = html
  }

  poll() {
    if (this.polling) {
      if (!location.href.match(/processed_migration_files\/\d/)) {
        this.polling = false
        return
      }
      fetch(location.href + "/show_output")
        .then(response => response.text())
        .then(html => {
          this.update(html)
          if (this.inProgress(html)) {
            this.polling = false
          }
        })
    }
  }

  inProgress(html) {
    const status = html.split('\n')[0].match(/>(.+?)</)[1]
    return status != 'running' && status != 'uploaded'
  }
}
