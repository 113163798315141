import { Controller } from "stimulus"
import './drag_sort.css'

import UrlHelper from '../../src/helper/url.js';

const SHADOW_CLASS = 'drag-sort-shadow'
const HOVER_CLASS = 'drag-sort-heading-hover'
const MOVING_CLASS = 'bg-light'
const MIN_DISTANCE_TO_ACTIVATION = 30;  //distance in pixels


export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    // console.log('drag_sort: ' + this.urlValue)

    this.mouseDownHandler = this.mouseDownHandler.bind(this)
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this)
    this.mouseUpHandler = this.mouseUpHandler.bind(this)
    this.escapeHandler = this.escapeHandler.bind(this)

    this.$header = $(this.element).find('thead>tr:first')
    this.$columns = this.$header.find('>th,>td')
    this.$columns.on('mousedown', this.mouseDownHandler)

    //this.$columns.css('position', 'relative')
    //this.$columns.prepend('<div style="position:absolute;top:0px;">X</div>')
  }

  findColumn(fromElement) {
    let elem = fromElement
    while (elem && elem.tagName.toLowerCase() != 'th') {
      elem = elem.parentElement
    }
    const th = elem;
    while (elem && elem.tagName.toLowerCase() != 'table') {
      elem = elem.parentElement
    }
    const table = elem;

    if (this.element == table ) { return th }
  }

  mouseDownHandler(e) {
    // Attach the listeners to `document`
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
    document.addEventListener('keydown', this.escapeHandler, false);

    e.preventDefault();
    this.movingTh = this.findColumn(e.target);
    if (this.movingTh) {
      this.movingFrom = e.clientX;
    }
  };

  mouseMoveHandler(e) {
    const distanceToMouseDown = this.movingFrom - e.clientX
    if (!this.isMoving && Math.abs(distanceToMouseDown) < MIN_DISTANCE_TO_ACTIVATION) {
      return
    }

    if (!this.isMoving) {
      this.isMoving = true
      this.movingTh.classList.add(MOVING_CLASS);
      this.shadow = this.createShadow();
      // compensate displacement for delay in activation
      this.shadow.style.left = this.shadow.getBoundingClientRect().x +
                               (distanceToMouseDown > 0 ? -MIN_DISTANCE_TO_ACTIVATION : MIN_DISTANCE_TO_ACTIVATION) + 'px';
    }

    const thUnderTheCursor = this.findColumn(e.target)
    let newSelection;

    // find the desired column (if left from middle this, if right the next)
    if (thUnderTheCursor) {
      const thRect = thUnderTheCursor.getBoundingClientRect()
      const leftSide = e.clientX - thRect.x - (thRect.width / 2) < 0
      newSelection = leftSide ? thUnderTheCursor : thUnderTheCursor.nextElementSibling
    }

    // -- draw shadow --
    if (!this.shadow) {
      //this.shadow = this.createShadow();
    }
    const rect = this.shadow.getBoundingClientRect();
    this.shadow.style.top = `${rect.top + e.movementY}px`;
    this.shadow.style.left = `${rect.left + e.movementX}px`;

    // -- draw mark on column --
    if (this.thSelected != newSelection) {
      if (this.thSelected) {
        this.thSelected.classList.remove(HOVER_CLASS);
      }
      if (newSelection) {
        newSelection.classList.add(HOVER_CLASS);
      }
    }
    this.thSelected = newSelection

  };

  mouseUpHandler(e) {
    if (this.willChangeSomething()) {
      this.callChange(this.movingTh.dataset.dragSortId, this.thSelected.dataset.dragSortId)
    }
    this.cleanUp();
  }

  willChangeSomething() {
    // inserting always before selected
    const notBeforeSame = this.thSelected != this.movingTh
    const notBeforeNext = this.thSelected != this.movingTh.nextElementSibling

    return this.thSelected && notBeforeSame && notBeforeNext
  }

  escapeHandler(e) {
    if(e.keyCode === 27) {
      e.preventDefault();
      this.cleanUp();
    }
  }

  cleanUp() {
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
    document.removeEventListener('keydown', this.escapeHandler);

    if (this.shadow) {
      this.$columns.removeClass([HOVER_CLASS, MOVING_CLASS]);
      this.shadow.remove();
      this.shadow = undefined;
      this.movingTh = undefined;
      this.thSelected = undefined;
      this.isMoving = false;
    }
  }

  createShadow() {
    // Get the bounding rectangle of column heading (th)
    const rect = this.movingTh.getBoundingClientRect();

    const shadow = document.createElement('div');
    this.element.parentNode.insertBefore(shadow, this.element);

    // Set the same position as the header, a little longer for a fade out effect
    shadow.classList.add(SHADOW_CLASS);
    shadow.style.left = `${rect.left}px`;
    shadow.style.top = `${rect.top}px`;
    shadow.style.width = `${rect.width}px`;
    shadow.style.height = `${rect.height + 20}px`;

    return shadow;
  }

  callChange(move_id, before_id) {
    console.log(`move_id: ${move_id} before_id: ${before_id} in ${this.urlValue}`)
    UrlHelper.callScript(this.urlValue, {
      method: 'patch',
      data: {
        move_id,
        before_id
      }
    })
  }
}