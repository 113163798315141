$.fn.wysihtml5.defaultOptions.toolbar = {
  'font-styles': false,
  'color': false,
  'emphasis': {
    'small': true
  },
  'blockquote': false,
  'lists': true,
  'html': false,
  'link': false,
  'image': false,
  'smallmodals': false
};

// see
// https://github.com/Voog/wysihtml/wiki/parserRules
// https://github.com/Voog/wysihtml/issues/221
$.fn.wysihtml5.defaultOptions.parserRules.tags = {
        'b':  {},
        'i':  {},
        'strong': {},
        'small': {},
        'em': {},
        'p': {},
        'br': {},
        'ol': {},
        'ul': {},
        'li': {},
        'h1': {},
        'h2': {},
        'h3': {},
        'h4': {},
        'h5': {},
        'h6': {},
        'blockquote': {},
        'u': 1,
        'div': 1, // FIX: Firefox do not add a <br> after every linebreak, it adds a <div>!!
        // 'img': {
        //   'check_attributes': {
        //     'width': 'numbers',
        //     'alt': 'alt',
        //     'src': 'url',
        //     'height': 'numbers'
        //   }
        // },
        // 'a':  {
        //   check_attributes: {
        //     'href': 'url' // important to avoid XSS
        //   },
        //   'set_attributes': {
        //     'target': '_blank',
        //     'rel': 'nofollow'
        //   }
        // },
        // 'span': 1,
        // to allow save and edit files with code tag hacks
        // 'code': 1,
        // 'pre': 1
      }