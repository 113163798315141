const armed_tag = '_ts_armed_'
class TableSortable {

  constructor($el) {
    $el = $el.not(`.${armed_tag}`)
    if (!$el.length) { return; }

    $el.addClass(armed_tag);

    this.table = $el;

    this.set_positions();
    this.init_table();
  }

  set_positions() {
    // loop through and give each task a data-pos
    // attribute that holds its position in the DOM
    return this.items().each( function(i) {
      return $(this).attr("data-sort-key", i + 1);
    });
  }

  items() {
    return $(this.table).find(".sortable");
  }

  init_table() {
    var that = this;
    this.table.sortable({
      items: that.items(),
      cursor: 'move',
      delay: 150,  // Needed to prevent accidental drag when trying to select
      helper: function (e, item) {
        var background_color, helper;
        var search_str = "[data-parent-id=" + item.data('row-id') + "]";

        if ($(search_str).length > 0) {
          // multidrag
          var width = item.css('width');
          background_color =  item.css('backgroundColor');
          var elements = item.add($(search_str)).clone();
          item.data('multidrag', elements).siblings(search_str).remove();
          helper = $('<table/>').addClass('table table-striped-OLD table-bordered').css('width', width);
          helper.append(elements);
          helper.css('backgroundColor', background_color);
          return helper;
        } else {
          background_color =  item.css('backgroundColor');
          helper = item.clone();
          helper.css('backgroundColor', background_color);
          return helper;
        }
      },
      stop: function (e, info) {
        var item = $(info.item);
        if (item.data('multidrag')) {
            item.after(item.data('multidrag')).remove();
          }

        return that.update_on_server(item);
      }

    });
  }
  update_on_server(item) {
    var that = this;
    var updated_order = [];
    // set the updated positions
    // populate the updated_order array with the new task positions
    that.items().each(function(i) {
      var changed = ($(this).data('row-id') === item.data('row-id'));
      return updated_order.push({
        id: $(this).data('row-id'),
        class_name: $(this).data('row-class-name'),
        name: $(this).data('name'),
        sort_key: i + 1,
        changed: changed
      });
    });
    return $.ajax({
      type: 'PATCH',
      url: that.table.data('update-order-url'),
      data: {
        order: updated_order,
        changed_id: item.data('row-id')
      }
    }).fail( function(data) {
      return that.table.sortable('cancel');
    });
  }
}

App.TableSortable = {
  init: () => new TableSortable($('.table-sortable')),
}
