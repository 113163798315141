import { Controller } from 'stimulus';
import * as XLSX from 'xlsx';

export default class extends Controller {
  static values = {
    color: String,
    column: String,
    prevCol: Number,
    prePaint: Boolean
  }

  connect() {
    this.prevColValue = -1;
    if(this.prePaintValue){
      document.addEventListener("prepaintColumns", (e) => this.selectColumn());
    }
    this.element.parentElement.setAttribute("style", "background-color: "+this.colorValue)
  }

  selectColumn() {
    var col = this.element.dataset.bipValue;
    if(col === undefined){
      return;
    }

    var upload_table = document.getElementById('upload-table');

    // selected column coloring
    upload_table.rows.forEach((tr, index) => {
      if(index === 0){
        tr.cells[col].innerHTML = tr.cells[col].innerHTML+" ("+this.columnValue+")";
      }

      if(tr.cells.length === upload_table.rows[0].cells.length){
        var td = tr.cells[col];
        td.setAttribute("style", "background-color: "+this.colorValue);
      }
    });

    // clear previous column
    if(this.prevColValue >= 0){
      upload_table.rows[0].cells[this.prevColValue].innerHTML = String.fromCharCode(this.prevColValue+65);

      upload_table.rows.forEach((tr, index) => {
        if(tr.cells.length === upload_table.rows[0].cells.length){
          var td = tr.cells[this.prevColValue];
          td.removeAttribute("style");
        }
      });
    };

    this.prevColValue = col;
    App.FreezeTable.refresh();
  }
}
