function init() {
  $('[data-bs-toggle="tooltip"], [title]').tooltip({
    trigger : 'hover'
  });
}

function hide() {
  $('.tooltip').tooltip('hide');
}

App.Tooltip = {
  init,
  hide
}