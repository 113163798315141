// ** here we register own events **************
// *********************************************
//
// events:
// modal:show, modal:hide - reacts to display or a change in the immediate child of modal.
// * triggered only once per change

//register custom events after the main page is loaded via turbolinks
$(document).on('turbolinks:load', function() {

  function attributeChanged(mutationsList, observer) {
    for (var i in mutationsList) {
      var mutation = mutationsList[i];
      if (mutation.attributeName == 'style') {
        var wasInvisible = mutation.target.getAttribute('been-invisible')

        if ($(observer.target).is(':visible') && wasInvisible) {
          $(document).trigger('modal:show');
          mutation.target.setAttribute('been-invisible', false)
        } else if (!wasInvisible) {
          $(document).trigger('modal:hide');
          mutation.target.setAttribute('been-invisible', true)
        }
      }
    }
  }

  function childAdded(mutationsList, observer) {
    //now we have the child node to register for display change events
    registerAttributeChangeEvent();

    for(var i in mutationsList) {
        var mutation = mutationsList[i];
        if (mutation.addedNodes.length > 0) {
          $(document).trigger('modal:show');
        } else if (mutation.removedNodes.length > 0) {
          //this is not used, since the nodes are left intact but just hidden
          //still, this could change in the futture
          $(document).trigger('modal:hide');
        }
    }
  };

  function registerAttributeChangeEvent() {
    // console.log('registering attibute listener');
    var observer = new MutationObserver(attributeChanged);
    var modal = document.getElementById('mainModal');
    if (modal) {
      observer.observe(modal , { attributes: true });
    }
  }

  //modals starts empty, we can attach the event just once here
  // console.log('registering child mutation listener');
  var observer = new MutationObserver(childAdded);
  var modal = document.getElementById('modal-holder');
  if (modal) {
    observer.observe(modal, { childList: true });
  }
});
