function cmd_go({url, modal = false} = {}) {
  if (modal) {
    App.Spinner.show()
    new App.Modal(url, true)
  } else {
    window.location = url
  }
}

App.flashCmdHelper = {
  handle: cmd => {
    // console.log(cmd)
    if (cmd.scroll) {
      const scroll_cmd = Object.keys(cmd.scroll)
      // scrolling need the whole page to be rendered, so wait a bit
      setTimeout(()=>App.ScrollHelper[scroll_cmd](cmd.scroll[scroll_cmd]), 100);
    }
    if (cmd.show) {
      const message = cmd.show.message
      const type = cmd.show.type || 'info'
      const remove_flash = cmd.show.remove_flash || 'true'
      if (!$('#flash_container_for_bip').children('#flash').length) {
        $('#flash_container_for_bip').append(`
        <div id="flash">
          <div class="float-right py-2 px-4 m-n2 link-pointer" onclick="$(\'#flash\').hide()">x</div>
          <span></span>
        </div>
        `)
      }
      const flash = $('#flash_container_for_bip').children('#flash')
      flash.removeClass().addClass(`alert alert-${type}`)
      flash.children('span').text(message)
      flash.show()
      if(remove_flash === 'true') {
        App.FlashRemover.init()
      }
    }

    if (cmd.go) cmd_go(cmd.go)
  }
}

export default App.flashCmdHelper
