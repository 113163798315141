// Computes time between start-time, end-time and duration field.
// If start or end times are changed the duration is modified, if duration is modified, then the end time is changed.
// the duration defines also the display quality of all fields (decimal e.g. 2,3h or formatted e.g. 2:16)
// aditionally input field selects all text and reformat them on blur.
// this element checks and arms itself, only once, after this events: turbolinks:load modal:shown bip:reload bip:load
// The element is namedspaced so multiple elements may exist on the same page.
// Example:
// <input data-compute-duration="some_namespace" data-start-time>
// <input data-compute-duration="some_namespace" data-end-time>
// <input data-compute-duration="some_namespace" data-duration="decimal|formatted">
(function () {
  var ATTR_NAME = 'data-compute-duration';
  var ATTR_START_TIME = 'data-start-time';
  var ATTR_END_TIME = 'data-end-time';
  var ATTR_DURATION = 'data-duration';

  function extractAndCheck(name) {
    var namespace = $('input[' + ATTR_NAME + '=' + name + ']');
    var startTime = namespace.filter('[' + ATTR_START_TIME + ']');
    var endTime = namespace.filter('[' + ATTR_END_TIME + ']');
    var duration = namespace.filter('[' + ATTR_DURATION + ']');

    //if (!startTime.length) console.log('missing startTime in compute-duration set ' + name);
    //if (!endTime.length) console.log('missing endTime in compute-duration set ' + name);
    //if (!duration.length) console.log('missing duration in compute-duration set ' + name);
    if (startTime.length && endTime.length && duration.length) {
      // we are arming the triggers, remove the attr to avoid binding them again
      //console.log(name + 'Armed. Removing attribute.');
      namespace.attr(ATTR_NAME, null);
      return new DurationCalculator(startTime, endTime, duration);
    } else if (duration.length) {
      // if only duration is active
      namespace.attr(ATTR_NAME, null);
      duration.on('focus', function(){ this.select(); });
    }
  }

  function DurationCalculator(startTime, endTime, duration) {
    var durationType = duration.attr(ATTR_DURATION);
    var decimal = true;
    if (durationType == 'formatted') {
      decimal = false;
    } else if (durationType == 'formatted') {
      console.log('warn: computation duration type ' + durationType + ' unknown falling back endTime decimal');
    }

    //init logic
    startTime.on('change', changeResult);
    endTime.on('change', changeResult);
    duration.on('change', changeTo);
    //should this be more general?
    [startTime, endTime, duration].forEach(function(elem) {
      var inDecimal = elem == duration ? decimal : false;
      elem.on('focus', function(){ this.select(); });
      elem.on('blur', function(){
        var displayStr = display(parse($(this)), inDecimal);
        if (displayStr) $(this).val(displayStr);
      });
    });

    function parse(elem) {
      var duration = elem.filter('[' + ATTR_DURATION + ']');
      // this should parse hours in different formats
      // better use moment js
      var timeStr = elem.val();
      var hours = 0;
      var minutes = 0;
      if (/:/.test(timeStr)) {
        var parts = timeStr.split(':');
        hours = parts[0];
        minutes = parts[1];
      } else if (/[,.]/.test(timeStr)) {
        if (elem.is(duration)) {
          return parseFloat(timeStr.replace(',', '.'));
        }
        else{
          var parts = timeStr.split(/[,.]/);
          hours = parts[0];
          minutes = parts[1];
        }
      } else {
        var onlyDigits = timeStr.replace(/\D/g, '');
        if (onlyDigits.length > 2) {
          minutes = onlyDigits.substr(-2, 2);
          if (parseInt(minutes) > 60) {
            //this can't be right, asume
            hours = onlyDigits;
            minutes = 0;
          } else {
            hours = onlyDigits.slice(0, -2);
          }
        } else {
          hours = onlyDigits;
        }
      }

      return parseFloat(hours) + parseFloat(minutes) / 60.0;
    }

    function display(hours, inDecimals) {
      if (!$.isNumeric(hours)) return;
      //endure midnight work :)
      if (hours < 0) hours += 24;
      if (inDecimals) {
        return hours.toLocaleString() + ' h';
      } else {
        var fullHours = Math.floor(hours);
        var minutes = Math.round((hours - fullHours) * 60);
        return fullHours.toString().padStart(2, 0) + ":" + minutes.toString().padStart(2, 0);
      }
    }

    function changeResult() {
      var startTimeHours = parse(startTime);
      var toHours = parse(endTime);
      if ($.isNumeric(startTimeHours) && $.isNumeric(toHours)) {
        duration.val(display(toHours - startTimeHours, decimal));
      }
    }

    function changeTo() {
      var startTimeHours = parse(startTime);
      var durationHours = parse(duration);
      if ($.isNumeric(startTimeHours) && $.isNumeric(durationHours)) {
        endTime.val(display(startTimeHours + durationHours, false));
      }
    }
  }

  function arm() {
    var elements = $('input[' + ATTR_NAME + ']');
    if (elements.length) {
      $.unique($(elements)
        .map(function (_, e) { return $(e).attr(ATTR_NAME); }))
        .each(function (_, name) {
          extractAndCheck(name);
        });
    }
  }
  // register interesting events
  $(document).on('turbolinks:load modal:show bip:reload bip:load', arm);
})()
