// Example:
//
// <button class="btn btn-primary"
// data-controller="toggle"
// data-action="toggle#toggle"
// data-toggle-target="button"
// data-toggle-on-text-value="<%= t('work.project_controllings.show.show_expert_view') %>"
// data-toggle-off-text-value="<%= t('work.project_controllings.show.hide_expert_view') %>"
// data-toggle-selector-value=".collapseExpertView"
// >

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  static values = {
    selector: String,
    onText: String,
    offText: String,
    isToggled: Boolean
  }

  connect() {
    this.buttonTarget.innerText = this.isToggledValue ? this.offTextValue : this.onTextValue
  }

  toggle() {
    this.isToggledValue = !this.isToggledValue
    if (this.isToggledValue) {
      this.buttonTarget.innerText = this.offTextValue
    } else {
      this.buttonTarget.innerText = this.onTextValue
    }

    if (this.hasSelectorValue) {
      const selection = $(this.selectorValue)
      selection.fadeToggle(this.isToggledValue)
    }
  }
}
