// Can we build namespaces in own folders, e.g. invoicing?

import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['grossAmount', 'netAmount', 'netPropSecurityDepositAmount'];
  }

  static values = { suggestedVatPercStr: String, securityDepositPercStr: String }

  calcNetAmountAndSecurityDeposit(event) {
    //let suggested_vat_perc = this.parseLocalStringToFloat(this.data.get('suggested-vat-perc-str'));
    let suggestedVatPerc = this.parseLocalStringToFloat(this.suggestedVatPercStrValue);
    let grossAmount = this.parseLocalStringToFloat(this.grossAmountTarget.value);
    let securityDepositPerc = this.parseLocalStringToFloat(this.securityDepositPercStrValue);

    let grossWithoutSecurityDeposit = grossAmount;

    if (this.hasNetSecurityDepositAmountTarget) {
      let netPropSecurityDepositAmount = this.parseLocalStringToFloat(this.netPropSecurityDepositAmountTarget.value);
      grossWithoutSecurityDeposit = grossAmount + netPropSecurityDepositAmount;
    }
    let netAmount = grossWithoutSecurityDeposit / (100 + suggestedVatPerc) * 100;
    this.netAmountTarget.value = this.floatToLocalString(netAmount);

    this.netPropSecurityDepositAmountTarget.value = this.floatToLocalString(netAmount * securityDepositPerc / (100 - securityDepositPerc));
  }

  // TODO: 
  // We need to find a better solution. At least we need to centralize this actions somewhere,
  // as looking for all those replaces will be time consuming.
  // Normally, this should be handled by the browser automatically,
  // the solution could be not to use a simple string input, but a float one.
  parseLocalStringToFloat(str) {
    return parseFloat(str.replace(".", "").replace(",", "."));
  }
  floatToLocalString(float) {
    return float.toFixed(2).replace(".", ",");
  }
}
