require('jquery-freeze-table')

// If you need to initialize this, do not do it directly, just call: App.init()
// If the table got partially modified (rows added, size changed, etc), call: App.FreezeTable.refresh()
App.FreezeTable = class FreezeTable {
  static init() {
    new App.FreezeTable($(".js-freeze-table, .js-freeze-table-fast"), false);
    // FIXME: js-freeze-table-modal doesnt work!
    new App.FreezeTable($(".js-freeze-table-modal"), true);
  }

  static clean() {
    $(".js-freeze-table._freeze_armed_,.js-freeze-table-fast._freeze_armed_").removeClass('_freeze_armed_');
  }

  static refresh() {
    $('.js-freeze-table, .js-freeze-table-fast').each((_, el) => $(el).freezeTable('update'));
  }

  constructor(elements, modal) {
    this.armed_tag = '_freeze_armed_';

    elements = elements.not(`.${this.armed_tag}`);
    if (!elements.length) { return; }

    elements.addClass(this.armed_tag);
    elements.each( (_, el) => {
      if (modal === undefined) { modal = $(el).hasClass('js-freeze-table-modal'); }
      const fastMode = $(el).hasClass('js-freeze-table-fast');
      const options = {
        'columnNum' : $(el).data('column-num'),
        'callback': this.after_init(el), // also triggered by update
        'namespace': Math.random(),
        'fixedNavbar': '.navbar',
        'fastMode': fastMode
      };
      if ($(el).data('column-num') === 0) {
        options.freezeColumn = false;
        delete options.columnNum;
      }
      if (modal) {
        options.container = '#mainModal.modal';
        $('.js-freeze-table-modal').find('.clone-head-table-wrap, .clone-column-table-wrap').remove();
      }

      $(el).freezeTable(options);
    })
  }

  after_init(el) {
    return function() {
      const cloned_tables = $(el).find('.clone-head-table-wrap, .clone-column-table-wrap');
      // remove form names from cloned tbody to avoid sending them to server
      cloned_tables.find('tbody input').attr('name', null);
      // the table is bip armed, before it is copied. The copies (for header/colums) weren't initialized but has the mark
      // so remove the mark and initialize them, so bip works normally.
      const bip_in_cloned_tables = $(el).find('.clone-head-table-wrap tbody .best_in_place, .clone-column-table-wrap tbody .best_in_place');
      bip_in_cloned_tables.removeClass('_bip_armed_');
      // cannot call App.init() ist it will reinitialize this
      App.Tooltip.init();
      new App.BestInPlace(bip_in_cloned_tables);
    };
  }
};
