// hides the flash message automatically after a while
// this class does not export anything.

const DEFAULT_SECONDS = 2
App.FlashRemover = {
  init: () => {
    const flash = $("#flash");
    if (flash.length === 0) { return; }

    const show_for_secs = flash.data('show_for_secs') || DEFAULT_SECONDS;
    const dont_hide = flash.data('dont_hide');
    if (!dont_hide) {
      setTimeout(() => flash.fadeOut(1000), show_for_secs * 1000);
    }
  }
}

$(document).on("turbolinks:load", () => App.FlashRemover.init())