App.ScrollHelper = {
  scrollToSelector: (selector, { middleTop = true, pulsate = true } = {}) => {
      if ($(selector).is(':visible')) {
      const offset = $(selector).offset();
      let top = offset.top;
      let left = offset.left;
      if (middleTop) {
        top -= $(window).height()/2
      }
      let anim = $('html, body').animate({
        scrollTop: top,
        scrollLeft: left
      });
      if (pulsate) {
        anim.promise().done(
          ()=>$(selector).fadeTo(600, 0.1).fadeTo(400, 1.0)
        )
      }
    }
  },
  scrollToPage: position => {
    let top;
    switch (position) {
      case 'bottom':
        top = $(document).height();
        break;
      default:
        console.log(`position '${position}' not implemented.`)
        return;
    }
    console.log(`top:${top}`)
    $('html, body').animate({
      scrollTop: top,
    });
  }
}

export default App.ScrollHelper;