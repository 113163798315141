// hanlde booking mover selection logic

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]
  static values = {
    url: String
  }
  connect() {
    this.toast = new bootstrap.Toast(document.getElementById('dailyTipToast'), {autohide: false});
    // To activate the daily display
    App.Session.doOncePerDay('daily_tip_last_seen', this.showNext.bind(this) )
  }

  showNext() {
    let url = App.UrlHelper.updateParameter('format', 'json', { url: this.urlValue} );
    url = App.UrlHelper.updateParameter('back', document.location, { url: url} )
    App.UrlHelper.callUrl(url).then(data => {
      this.contentTarget.innerHTML = data.html_content;
      this.toast.show()
    })
    App.Session.store('daily_tip_last_seen', Date.now());
  }
}