import { Controller } from 'stimulus';
import * as XLSX from 'xlsx';

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.loadSpreadsheet();
  }

  loadSpreadsheet() {
    var req = new XMLHttpRequest();

    req.open("GET", this.urlValue, true);
    req.responseType = "arraybuffer";

    req.onload = function(e) {
      var data = new Uint8Array(req.response);
      var workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // display as html-table:
      var htmlstr = XLSX.utils.sheet_to_html(worksheet);
      htmlstr = htmlstr.replace(/<table/g, '<table id="upload-table" class="table table-striped-OLD table-bordered table-sm"');
      document.getElementById("spreadsheet").innerHTML = "<div class='js-freeze-table'>"+htmlstr+"</div>";

      var upload_table = document.getElementById('upload-table');

      var largest_cell_count = 0;
      for (var i = 0; i < upload_table.rows.length; i++) {
        if (upload_table.rows.item(i).cells.length > largest_cell_count) {
          largest_cell_count = upload_table.rows.item(i).cells.length;
        }
      }

      var new_thead = document.createElement("thead");
      new_thead.setAttribute("class", "table-light");
      var first_tr = document.createElement("tr");

      var alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
      for(var i=0; i < largest_cell_count; i++){
        var new_td = document.createElement("td");
        var td_content = document.createTextNode(alphabet[i]);
        new_td.appendChild(td_content);
        first_tr.appendChild(new_td);
      }
      new_thead.appendChild(first_tr);
      upload_table.prepend(new_thead);

      // needed to re-init freeze-table:
      App.init();

      const connectedEvent = new CustomEvent("prepaintColumns", {
        detail: {
          name: "prepaintColumns",
        },
      });
      document.dispatchEvent(connectedEvent);
    };
    req.send();
  }
}
