// show check-list entries of the selected task

import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    //console.log('mark_leaf_controller.js: ', this.element)
    // this.element.textContent = 'Hello World!'
  }
  toggleMarkLeaf(event) {
    let table_row = $(this.element).closest('tr');
    let object_class = table_row.data('object-class');
    let object_id = table_row.data('object-id');
    let table_rows = $('[data-object-class="'+object_class+'"][data-object-id="'+object_id+'"]');
    table_rows.toggleClass('planning2_leaf_markation');

    let marked_leaf_ids_not_uniq = $('.planning2_leaf_markation[data-object-class="Work::Leaf"]').map(function() {
          return $(this).attr('data-object-id');
        }).get();
    let marked_leaf_ids = marked_leaf_ids_not_uniq.filter((item, index) => marked_leaf_ids_not_uniq.indexOf(item) === index);

    if (marked_leaf_ids.length > 0) {
      $('.button_to_filter_on_leaf_ids').removeClass('hide');
    } else {
      $('.button_to_filter_on_leaf_ids').addClass('hide');
    };

    let base_url = $('.button_to_filter_on_leaf_ids').data('base-url');
    let param_str = marked_leaf_ids.join(",");
    let filtered_url = base_url+"?leaf_ids="+param_str

    $('.button_to_filter_on_leaf_ids').attr("href", filtered_url);

  }
}
