import { Controller } from 'stimulus';
import videojs from 'video.js'

export default class extends Controller {
  static targets = ["instance"]

  connect() {
    this.players = [];
    this.instanceTargets.forEach(instance => { this.players.push(videojs(instance)) })

    var self = this
    var modals = document.getElementsByClassName('modal')
    modals.forEach(modal => {
      modal.addEventListener('hidden.bs.modal', function (event) {
        self.players.forEach(player => player.pause())
      })
    })
  }
}
