App.CreditCard = function CreditCard(el) {
  const submit_btn = $('#card-button');
  const err = $('#card-errors');
  // this is a fallback msg, in case stripe does not respond or changes the api.
  const fallback_msg = 'Wir konnten Ihre Zahlungsmethode nicht authentifizieren. Wählen Sie eine andere Zahlungsmethode und versuchen Sie es erneut.';

  this.el = el;
  var stripe_key = $('meta[name="stripe-key"]').attr("content");

  var stripe = Stripe(stripe_key);

  // Create an instance of Elements.
  var elements = stripe.elements();
  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  var style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': { color: '#aab7c4'
    }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  // Create an instance of the card Element.
  var cardElement = elements.create('card', {style: style});
  // Add an instance of the card Element into the `card-element` <div>.
  cardElement.mount('#card-element');

  var cardholderName = document.getElementById('cardholder-name');
  var setupForm = document.getElementById('setup-form');
  var clientSecret = setupForm.dataset.secret;

  setupForm.addEventListener('submit', function(ev) {
    submit_btn.prop("disabled", true);
    ev.preventDefault();
    stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardholderName.value,
          },
        },
      }
    ).then(function(result) {
      if (result.error) {
        // Display error.message in your UI.
        console.log(result.error)
        err.text(result.error.message || fallback_msg)
        submit_btn.prop("disabled", false);
      } else {
        // The setup has succeeded. Display a success message.
        console.log("ok!");
        console.log(result);

        //tell server we are done
        let hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'paymentMethod');
        hiddenInput.setAttribute('value', result.setupIntent.payment_method);
        setupForm.appendChild(hiddenInput);

        setupForm.submit();
      }
    });
  });

};
