import UrlHelper from './url.js';


const ARMED_CLASS = 'js-click-armed'

function extractParams(node) {
  return {
    link: node.data("href"),
    modal: node.data("modal"),
    remote: node.data("remote"),
    method: node.data("method"),
    reload_page_after_close: node.data("reload-page-after-close"),
    call_url_after_close: node.data('call-url-after-close'),
    target: node.data("target")
  }
}

function handle_click({modal, link, remote, method, reload_page_after_close, call_url_after_close, target} = {}) {
  if (modal) {
    new App.Modal(link, reload_page_after_close, call_url_after_close);
  } else if (remote) {
    UrlHelper.callScript(link, {method: method});
  } else if (target == '_blank') {
    UrlHelper.goToPageInNewTab(link, {method: method});
  } else {
    UrlHelper.goToPage(link, {method: method});
  }
}

function init() {
  $('.js-click-cell').not(`.${ARMED_CLASS}`).each((_i, e) => {
    const td = $(e);
    td.addClass(ARMED_CLASS);
    const params = extractParams(td);
    $(td).on('click', () => handle_click(params))
  });
  $('.js-click-row td:not(.js-not-clickable):not(.js-click-cell)').not(`.${ARMED_CLASS}`).each((_i, e) => {
    const td = $(e);
    td.addClass(ARMED_CLASS);
    const tr = td.parent();
    const params = extractParams(tr);
    $(td).on('click', () => handle_click(params))
  });

}

function disarm() {
  // when leaving page, disarm so it can be rearmed when hitting back button
  $(`.${ARMED_CLASS}`).removeClass(ARMED_CLASS);
}

App.ClickInTable = {
  init
}

$(document).on("turbolinks:before-cache", () => disarm());
