// Typeahead is deprecated, the "newer" version is already 6 Years old.
// it seems to do nothing regarding too many calls, and we havee no separate system for this.

const DELAY_CALL_MILLIS = 1000;

function init(node) {
  if (node.length === 0) { return; }

  node.typeahead({
      highlight: true,
      minLength: 3
    },
    {
      source: (query, syncResults, asyncResults) => {
        const task_id = $('.js-input-for-typeahead').val()
        if (task_id == '') { return; }

        const url = `/work/tasks/${task_id}/descriptions.json`
        const query_url = `${url}?q=${query}`

        const typeaheadCache = node.data('typeahead-cache') || node.data('typeahead-cache', {});

        const mayReuse = typeaheadCache.lastResponse &&
          typeaheadCache.lastResponse.task_id == task_id &&
          query.includes(typeaheadCache.lastResponse.query)

        if (mayReuse) {
          // we have a submatch, reuse previous response
          //
          syncResults($.map(typeaheadCache.lastResponse.data, (response) => { if (response.includes(query)) return { response } }))
          // console.log(`reusing: ${typeaheadCache.lastResponse.query}`)
          return
        }

        if (typeaheadCache.timer) {
          // we have a waiting call, remove it.
          clearTimeout(typeaheadCache.timer);
          typeaheadCache.timer = null;
        }

        typeaheadCache.timer = setTimeout(()=>{
          $.get(query_url, (data) => {
            typeaheadCache.lastResponse = { query, data, task_id };
            asyncResults($.map(data, (response) => { if (response.includes(query)) return { response } }))
          })
        }, DELAY_CALL_MILLIS)

         // console.log(node.data('typeahead-cache'))
      },
      display: (item) => item.response
    }
  )
}
App.Typeahead = {
  init: () => init($(".js-typeahead"))
}
